<template>
  <v-combobox
    v-model="candidates"
    :items="candidacies"
    :label="$t('selectcandidtes')"
    chips
    item-text="fullName"
    item-value="id"
    multiple
    return-object
    dense
    outlined
    hide-details
    required
    :rules="[required]"
    @change="$emit('change', candidates)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        small
        @click="data.select"
        @click:close="removeCandidate(data.item)"
      >
        <v-avatar left size="24">
          <v-img
            :src="
              data.item.photo
                ? `${apiUrl}/images/candidate/${data.item.photo}`
                : `/avatar.png`
            "
          ></v-img>
        </v-avatar>
        <!-- {{ data.item }} -->
        {{ data.item.fullName.split(" ")[0] }}
        <span v-if="data.item.fullName.split(' ').length > 1">{{
          data.item.fullName.split(" ")[
            data.item.fullName.split(" ").length - 1
          ]
        }}</span>
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar size="24">
          <img
            :src="
              data.item.photo
                ? `${apiUrl}/images/candidate/${data.item.photo}`
                : `/avatar.png`
            "
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
          <v-list-item-subtitle
            v-html="data.item.profession"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-combobox>
</template>

<script>
import { API_URL } from "@/api";
export default {
  name: "CandidateCombobox",

  props: {
    candidacies: { type: Array },
    currentCandidates: { type: Array },
  },

  data: () => ({
    apiUrl: API_URL,
    candidates: [],
  }),

  watch: {
    currentCandidates(val) {
      if (val.length === 0) {
        this.candidates = [];
      }
    },
  },

  methods: {
    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Required.";
      }
      return !!value || "Required.";
    },

    removeCandidate(item) {
      const index = this.candidates.findIndex(
        (findCandidate) => findCandidate.id === item.id
      );

      if (index >= 0) {
        this.candidates.splice(index, 1);
      }
    },
  },
};
</script>