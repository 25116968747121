<template>
  <v-dialog v-model="dialog" persistent max-width="750">
    <v-card class="pb-5 px-5" v-if="test.questions">
      <v-card-title class="justify-center">
        <span class="headline">{{ $t(test.area) }}</span>
      </v-card-title>
      <!-- <pre>{{ test.questions[position].observations }}</pre> -->
      <!-- <pre>{{ scoreObs.questions[position] }}</pre>
        <pre>{{ scoreObs.testId }}</pre>
        <pre>{{ scoreObs.result }}</pre> -->
      <v-card-text>
        <v-row class="pt-1 justify-center" no-gutters>
          <v-col
            v-show="test.questions[position].picture"
            cols="12"
            md="12"
            class="pb-5 text-center justify-center"
          >
            <v-img
              :src="`${apiUrl}/images/test/${test.questions[position].picture}`"
              aspect-ratio="2"
              contain
            ></v-img>
          </v-col>
          <v-col cols="12" md="12" lg="12" xlg="12">
            <div class="text-justify">
              <v-textarea
                :value="
                  test.questions[position].question +
                    ' (' +
                    test.questions[position].percentage +
                    '%)'
                "
                auto-grow
                dense
                hide-details
                flat
                readonly
                solo
              />
              <!-- <span class="title black--text"
                  >{{ test.questions[position].question }}({{
                    test.questions[position].percentage
                  }}%)</span
                > -->
            </div>
            <div class="text-center">
              <v-btn
                small
                dense
                color="error"
                v-if="test.questions[position].file"
              >
                <v-icon class="ma-1" small>mdi-cloud-download</v-icon>
                &nbsp;&nbsp;<a
                  :href="
                    `${apiUrl}/files/test/${test.questions[position].file}`
                  "
                  target="_blank"
                  class="text-decoration-none white--text"
                  >{{ $t("download_file") }}</a
                >
              </v-btn>
              <v-btn
                small
                dense
                color="warning"
                v-if="test.questions[position].fileResponse"
                class="ml-1"
              >
                <v-icon class="ma-1" small>mdi-cloud-download</v-icon>
                &nbsp;&nbsp;<a
                  :href="
                    `${apiUrl}/files/test/${test.questions[position].fileResponse}`
                  "
                  target="_blank"
                  class="text-decoration-none white--text"
                  >{{ $t("download_file_response") }}</a
                >
              </v-btn>
              <div
                v-if="
                  test.questions[position].alternatives[0].answer &&
                    !test.questions[position].openQuestion
                "
                class="mt-1"
              >
                <p>
                  <span style="color: green">{{ $t("green") }}</span
                  >: {{ $t("green_text") }}
                </p>
                <p style="margin-top: -3%">
                  <span style="color: red">{{ $t("red") }}</span
                  >: {{ $t("red_text") }}
                </p>
                <p style="margin-top: -3%">
                  <span style="color: orange">{{ $t("orange") }}</span
                  >: {{ $t("orange_text") }}
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="12" lg="12" xlg="12" class="pa-2">
            <v-row
              no-gutters
              v-if="test.questions[position].alternatives[0].answer"
            >
              <v-col
                cols="12"
                md="12"
                class="pa-1"
                v-for="(alternative, index) in test.questions[position]
                  .alternatives"
                :key="index"
              >
                <v-card
                  class="mx-auto"
                  max-width="650"
                  flat
                  fluid
                  style="border: 1px solid #f7a400; border-radius: 5px"
                  :color="
                    alternativeColor(alternative) != 'primary'
                      ? alternativeColor(alternative)
                      : false
                  "
                  :outlined="alternativeColor(alternative) === 'primary'"
                  @click="selectAnswer(index)"
                  v-show="!test.questions[position].openQuestion"
                >
                  <v-card-text style="padding-top: -20px">
                    <div
                      class="flex-grow-1 text-center"
                      :style="{
                        color: alternative.response
                          ? '#fff'
                          : alternativeColor(alternative) === 'primary'
                          ? '#000'
                          : '#fff',
                      }"
                    >
                      {{ alternative.answer }}
                    </div>
                  </v-card-text>
                </v-card>
                <v-row
                  class="ma-0"
                  v-show="test.questions[position].openQuestion"
                >
                  <v-col cols="12" md="12">
                    <span class="black--text text-body-1">
                      {{ alphabetArray[index].toLowerCase() + ")" }}
                      {{ alternative.answer }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      v-model="alternative.textResponse"
                      :label="$t('answer')"
                      dense
                      hide-details
                      rows="1"
                      row-height="20"
                      readonly
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!-- <v-btn
                    :color="alternativeColor(alternative)"
                    dark
                    tile
                    :outlined="alternativeColor(alternative) == '#F6A400'"
                    block
                    class="align-center py-3 "
                    @click="selectAnswer(index)"
                  >
                   <div class="flex-grow-1 text-center" :style="{'color': alternative.response ? '#000':'#000'}" v-html=" alternative.answer.length> 55 ? alternative.answer.substring(0, 55)+'-<br/>'+alternative.answer.substring(55, alternative.answer.length):alternative.answer.substring(0, alternative.answer.length)">
              
                   </div>
                  </v-btn> -->
              </v-col>
            </v-row>
            <v-textarea
              v-else
              v-model="test.questions[position].answer"
              rows="3"
              readonly
              :label="$t('answer')"
              class="pt-5"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-row class="pt-2 justify-center px-3" no-gutters>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-expansion-panels class="mx-auto">
                <v-expansion-panel style="box-shadow: 0px 0px 0px 0px">
                  <v-expansion-panel-header class="primary--text">{{
                    $t("test_review_title")
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col
                      cols="12"
                      md="12"
                      class="text-left"
                      v-if="test.questions[position].objective"
                    >
                      <p class="subtitle-2 black--text">
                        <span class="font-weight-black">{{
                          $t("test_review_subtitle")
                        }}</span
                        ><br />
                        <v-textarea
                          :value="test.questions[position].objective"
                          auto-grow
                          dense
                          hide-details
                          flat
                          readonly
                          solo
                        />
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      class="text-left"
                      v-if="test.questions[position].implication"
                    >
                      <p class="subtitle-2 black--text">
                        <span class="font-weight-black">{{
                          $t("test_review_subtitle_2")
                        }}</span
                        ><br />
                        <v-textarea
                          :value="test.questions[position].implication"
                          auto-grow
                          dense
                          hide-details
                          flat
                          readonly
                          solo
                        />
                      </p>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-col>
          <v-row
            class="pt-2 justify-center px-3"
            no-gutters
            v-if="test.questions[position].openQuestion"
          >
            <!-- <v-col cols="12">
                <v-divider></v-divider>
              </v-col> -->
            <v-expansion-panels class="">
              <v-expansion-panel style="box-shadow: 0px 0px 0px 0px">
                <v-expansion-panel-header class="primary--text">{{
                  $t("test_obs_score")
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card class="" flat>
                    <v-card-text>
                      <v-row
                        no-gutters
                        v-for="(item, index) in test.questions[position]
                          .observations"
                        :key="index"
                      >
                        <div class="d-flex flex-column pb-2">
                          <div>
                            <span class="black--text font-weight-bold">{{
                              item.name
                            }}</span>
                            <span class="caption"> | {{ item.time }}</span>
                          </div>
                          <div>
                            <v-hover v-slot="{ hover }" open-delay="100">
                              <span class="pt-1 showBtn">
                                <span class="black--text"
                                  >{{ $t("score_assigned") }}:
                                  {{ item.result }}</span
                                >
                                <br />
                                {{ item.textObservation }}
                                <v-btn
                                  v-if="item.name === currentUser.name"
                                  id="btn1"
                                  :class="{ 'on-hover': hover }"
                                  :style="hover ? 'display:inline' : ''"
                                  text
                                  icon
                                  x-small
                                  color="primary"
                                  @click="edit(position, index)"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="item.name === currentUser.name"
                                  id="btn2"
                                  :class="{ 'on-hover': hover }"
                                  :style="hover ? 'display:inline' : ''"
                                  text
                                  icon
                                  x-small
                                  color="error"
                                  @click="deleteObservation(position, index)"
                                >
                                  <v-icon>mdi-delete </v-icon>
                                </v-btn>
                              </span>
                            </v-hover>
                          </div>
                        </div>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="score"
                            tile
                            title
                            :label="$t('RemainingScore')"
                            dense
                            outlined
                            type="number"
                            :max="test.questions[position].percentage"
                            min="0"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="9">
                          <v-textarea
                            v-model="obs"
                            :label="$t('obs')"
                            outlined
                            dense
                            auto-grow
                            hide-details
                            rows="1"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="px-5">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        small
                        v-if="indexObservation === ''"
                        @click="sendObservation(position)"
                        >{{ $t("Add") }}</v-btn
                      >
                      <v-btn
                        color="primary"
                        small
                        v-else
                        @click="sendEditeobservation(position)"
                        :loading="loadind"
                        :disabled="loadind"
                        >{{ $t("update") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex flex-row justify-end mt-8 mb-2 px-6" no-gutters>
          <v-btn
            :disabled="position <= 0"
            color="primary"
            text
            @click="backQuestion()"
          >
            {{ $t("Back") }}
          </v-btn>
          <v-btn
            v-if="position < test.questions.length - 1"
            color="primary"
            tile
            @click="nextQuestion()"
          >
            {{ $t("next") }} {{ position + 1 }}/{{ test.questions.length }}
          </v-btn>
          <v-btn v-else color="primary" tile @click="nextQuestion()"
            >{{ $t("finish") }} {{ position + 1 }}/{{
              test.questions.length
            }}</v-btn
          >
        </v-row>
      </v-card-actions>
      <WhirelabSnackbar
        :snackbar="snackbar"
        :timeout="timeout"
        :text="snackbar_test"
        :close="closeSnackbar"
        :color="color"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { SOLVE_TEST_MUTATION } from "@/modules/candidate/modules/test/graphql/Mutation.service";
import { formatError } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import WhirelabSnackbar from "./../../../components/WhirelabSnackbar.vue";
export default {
  name: "PreviewTestDialog",
  components: {
    WhirelabSnackbar,
  },
  props: {
    dialog: Boolean,
    test: Object,
  },
  data: () => ({
    apiUrl: API_URL,
    position: 0,
    loadind: false,
    questions: [
      {
        question: "",
        alternatives: [
          {
            answer: "",
            value: false,
            response: false,
          },
        ],
        totalCorrect: 0,
        status: false,
        picture: undefined,
      },
    ],
    alphabetArray: Array.from(
      {
        length: 26,
      },
      (_, index) => String.fromCharCode(65 + index)
    ),
    scoreObs: {
      testId: "",
      questions: "",
      result: "",
    },
    score: 0,
    obs: "",
    timeout: 900,
    snackbar: false,
    snackbar_test: "",
    color: "",
    indexObservation: "",
  }),
  created() {
    this.position = 0;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    ...mapActions({
      updateTestState: "test/updateTest",
    }),
    alternativeColor(alternative) {
      if (alternative.response && alternative.value) {
        return "success";
      } else if (alternative.response && !alternative.value) {
        return "error";
      } else if (alternative.value) {
        return "orange";
      } else {
        return "primary";
      }
    },
    nextQuestion() {
      if (this.position < this.test.questions.length - 1) {
        this.position += 1;
      } else {
        this.position = 0;
        this.$emit("close");
      }
    },
    backQuestion() {
      if (this.position > 0) {
        this.position -= 1;
      }
    },
    getTime() {
      let currentTime = new Date();
      let hours = currentTime.getHours();
      let minutes = currentTime.getMinutes();
      let seconds = currentTime.getSeconds();
      return `${hours}:${minutes}:${seconds}`;
    },
    cleanFields() {
      this.score = 0;
      this.obs = "";
      this.indexObservation = "";
    },
    sendObservation(index) {
      this.scoreObs.testId = this.test.id;
      this.scoreObs.questions = this.test.questions;
      this.scoreObs.result = this.test.result;
      let totaScore = this.test.questions[index].observations
        .map((obs) => {
          let sum = 0;
          return (sum += obs.result);
        })
        .map(Number)
        .reduce((acc, curr) => acc + curr, 0);

      if (
        parseInt(this.score) <= parseInt(this.test.questions[index].percentage)
      ) {
        if (this.obs != "") {
          if (totaScore === this.test.questions[index].percentage) {
            this.score = 0;
          }
          if (this.score > this.test.questions[index].percentage) {
            this.score = 0;
          }

          this.scoreObs.questions[index].observations.push({
            name: this.currentUser.name,
            time: this.getTime(),
            result: this.score.toString(),
            textObservation: this.obs,
          });
          console.log(this.scoreObs.result, totaScore);
          this.scoreObs.questions[index].observations.map((obs) => {
            delete obs.id;
            return obs;
          });
          this.save(this.scoreObs);
        }
      } else {
        this.timeout = 2000;
        this.color = "red";
        this.snackbar = true;
        this.snackbar_test = this.$t("The_score_cannot_exceed_100").replace(
          "100",
          this.test.questions[index].percentage
        );
        this.closeSnackbar();
      }
    },
    deleteObservation(pos, index) {
      this.scoreObs.questions = this.test.questions;
      this.scoreObs.result = this.test.result;
      this.scoreObs.testId = this.test.id;
      this.scoreObs.questions[pos].observations.splice(index, 1);
      this.scoreObs.questions[pos].observations.map((obs) => {
        delete obs.id;
        return obs;
      });
      this.save(this.scoreObs);
    },
    edit(pos, index) {
      this.indexObservation = index;
      this.scoreObs.questions = this.test.questions;
      this.scoreObs.result = this.test.result;
      this.scoreObs.testId = this.test.id;

      this.score = this.scoreObs.questions[pos].observations[index].result;
      this.obs = this.scoreObs.questions[pos].observations[
        index
      ].textObservation;
    },
    sendEditeobservation(pos) {
      // let totaScore = this.test.questions[pos].observations
      //   .map((obs) => {
      //     let sum = 0;
      //     return (sum += obs.result);
      //   })
      //   .map(Number)
      //   .reduce((acc, curr) => acc + curr, 0);
      // let subEdit = parseInt(
      //   this.scoreObs.questions[pos].observations[this.indexObservation].result
      // );
      // totaScore = totaScore - subEdit;
      if (
        parseInt(this.score) <= parseInt(this.test.questions[pos].percentage)
      ) {
        this.scoreObs.questions[pos].observations[
          this.indexObservation
        ].result = this.score;
        this.scoreObs.questions[pos].observations[
          this.indexObservation
        ].textObservation = this.obs;
        this.scoreObs.questions[pos].observations.map((obs) => {
          delete obs.id;
          return obs;
        });

        this.save(this.scoreObs);
      } else {
        this.timeout = 2000;
        this.color = "red";
        this.snackbar = true;
        this.snackbar_test = this.$t("The_score_cannot_exceed_100").replace(
          "100",
          this.test.questions[pos].percentage
        );
        this.closeSnackbar();
      }
    },
    async save(input) {
      try {
        this.loadind = true;

        const { data } = await this.$apollo.mutate({
          mutation: SOLVE_TEST_MUTATION,
          variables: {
            ...input,
          },
        });
        this.cleanFields();
        // this.updateTestState(data.solveTest);
        console.log(data);
        if (data) {
          this.$emit("fetch");
          this.loadind = false;
        }
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    closeSnackbar() {
      setTimeout(() => {
        this.color = "";
        this.snackbar = false;
      }, this.timeout);
    },
  },
};
</script>
