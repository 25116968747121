<template>
  <div class="fill-heigt" style="background-color: #fff">
    <!-- <v-container class="fill-heigt" fluid> -->
      <v-row class="mt-0 mx-0" style="margin-bottom: -30px;">
       <!-- <pre>{{ candidate_cv }}</pre>jbhghg -->
        <v-btn icon color="primary" @click="routerBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="ma-1"
              color="primary"
              @click="generatePDF()"
            >
              <!-- {{ $t("pdf") }} -->
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("print") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="ma-1"
              color="primary"
              @click="$emit('openShareDialog')"
            >
              <!-- {{ $t("share") }} -->
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("share") }}</span>
        </v-tooltip>
      </v-row>
    <!-- </v-container> -->
    <div class="mt-10">
      <v-container class="afinar">
        <vue-html2pdf
          style="height: 2400px; margin-top: -3%"
          :show-layout="true"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1200"
          :filename="candidateId"
          :pdf-quality="4"
          :manual-pagination="true"
          pdf-format="a4"
          :html-to-pdf-options="htmlToPdfOptions"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
        </vue-html2pdf>
      </v-container>
    </div>
    <!-- <ShareCVDialog /> -->
  </div>
</template>

<script>
import VueHtml2pdf from "../../../components/vue-html2pdf.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { API_URL } from "@/api";
// import ShareCVDialog from "../components/ShareCVDialog.vue";
export default {
  name: "CV",
  components: {  VueHtml2pdf },
  props:["candidate_cv"],
  data: () => ({
    apiUrl: API_URL,
    candidateId: undefined,
    candidate: {},
    skipQuery: true,
    publicPath: process.env.BASE_URL,
    filter: {
      jobType: "job",
      areasInterest: undefined,
      belongsToMany: undefined,
      favorites: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      ids: undefined,
      limit: 4,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
    },
    htmlToPdfOptions: {
      margin: 0,
      filename: `hehehe.pdf`,
      image: {
        type: "jpeg",
        quality: 4,
      },
      enableLinks: true,
      html2canvas: {
        scale: 5,
        useCORS: true,
      },
      // pagebreak: { mode: "avoid-all" },
      jsPDF: {
        format: "letter",
        // floatPrecision: "smart",
        // orientation: "portrait",
      },
    },
  }),

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    candidate() {
      return this.currentUser ? this.currentUser.candidate : {};
    },
  },
  created() {
    this.start();
  },
  mounted() {},
  methods: {
    generatePDF() {
      window.open(
        `${this.apiUrl}/pdf/cv/${this.$i18n.locale}/${this.candidateId}/report.pdf`,
        "_blank"
      );
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    routerBack() {
      this.$emit('back')
      switch (this.$route.query.from) {
        case "candidacies":
          this.$router.push({
            path: `/dashboard/entity/candidacies/profile/${this.candidateId}`,
            query: {
              filter: this.filter,
              from: "candidacies",
              vacancyId: this.$route.query.vacancyId,
            },
          });
          break;

        case "candidacies/unsolicited":
          this.$router.push({
            path: `/dashboard/entity/candidacies/unsolicited/`,
            query: {
              filter: this.filter,
              vacancyId: this.$route.query.vacancyId,
            },
            from: "candidacies/unsolicited",
          });
          break;

        case "ranking":
          this.$router.push({
            path: `/dashboard/entity/ranking/profile/${this.candidateId}`,
            query: {
              filter: this.filter,
              from: "ranking",
              vacancyId: this.$route.query.vacancyId,
            },
          });
          break;
        case "candidates":
          this.$router.push({
            path: `/dashboard/entity/candidates/profile/${this.candidateId}`,
            query: {
              filter: this.filter,
              from: "entity",
              vacancyId: this.$route.query.vacancyId,
            },
          });
          break;
        default:
         // this.$router.back();
      }
    },

    openShareDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareCandidateCV", this.candidate.id);
    },
    start() {
      this.candidateId = this.$route.params.id?this.$route.params.id:this.candidate_cv.id;
      this.filter = this.$route.query.filter
        ? { ...this.$route.query.filter }
        : this.filter;
      this.filter.limit = 4;
      console.log(this.filter);
    },
  
  },
};
</script>
<style scoped>
.afinar {
  max-width: 800px;
  align-content: center;
  margin: auto;
}
</style>
