var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"px-10 pt-12 pb-10"},[_c('v-radio-group',{model:{value:(_vm.selectedStage),callback:function ($$v) {_vm.selectedStage=$$v},expression:"selectedStage"}},[_c('v-row',{staticClass:"d-flex flex-column lign-center mb-5"},[_c('div',{staticClass:"text-left pa-5",class:{ 'option-active': _vm.selectedStage === _vm.currentStage.name }},[_c('v-radio',{attrs:{"value":_vm.currentStage.name},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(
                  _vm.$t('options_re_sachedule_text_1', {
                    stagePosition: _vm.currentStage.index,
                    stageName: _vm.currentStage.name,
                  })
                )}})]},proxy:true}])})],1),(_vm.nextStage.index)?_c('div',{staticClass:"text-left pa-5",class:{ 'option-active': _vm.selectedStage === _vm.nextStage.name }},[_c('v-radio',{attrs:{"value":_vm.nextStage.name},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(
                  _vm.$t('options_re_sachedule_text_2', {
                    stagePosition: _vm.nextStage.index,
                    stageName: _vm.nextStage.name,
                    previewStage: _vm.currentStage.index,
                  })
                )}})]},proxy:true}],null,false,4292754433)})],1):_vm._e()])],1),_c('v-row',{staticClass:"justify-end px-10"},[_c('v-btn',{attrs:{"text":"","samll":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.schedule()}}},[_vm._v(" "+_vm._s(_vm.$t("continue"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }