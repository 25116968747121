<template>
  <v-combobox
    v-model="candidateEmails"
    :filter="filter"
    :hide-no-data="!search"
    :items="items"
    :search-input.sync="search"
    hide-selected
    :label="$t('candidate_email')"
    multiple
    dense
    small-chips
    outlined
    hide-details
    item-value="text"
    @change="changed()"
    :rules="rules"
  >
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading">Create</span>
        <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :color="`${item.color} lighten-3`"
        :input-value="selected"
        label
        small
      >
        <span class="pr-2">
          {{ item.text }}
        </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
    <template v-slot:item="{ index, item }">
      <v-text-field
        v-if="editing === item"
        v-model="editing.text"
        autofocus
        flat
        background-color="transparent"
        hide-details
        solo
        @keyup.enter="edit(index, item)"
        :rules="rules"
      ></v-text-field>
      <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
        {{ item.text }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-list-item-action @click.stop>
        <v-btn icon @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? "mdi-pencil" : "mdi-check" }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: "CandidateCustomCombobox",
  props: ["rules"],
  data: () => ({
    candidateEmails: [],
    activator: null,
    attach: null,
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
    editing: null,
    editingIndex: -1,
    items: [{ header: "Select an option or create one" }],
    nonce: 1,
    model: [
      {
        text: "Foo",
        color: "blue",
      },
    ],
    x: 0,
    search: null,
    y: 0,
  }),
  computed: {
    // requiredRules() {
    //   return [(v) => !!v || this.$t("required_field")];
    // },
  },
  watch: {
    candidateEmails(val, prev) {
      if (val.length === prev.length) return;

      this.candidateEmails = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },

  methods: {
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },

    changed() {
      const emailCandidates = this.candidateEmails.map(
        (mapCandidate) => mapCandidate.text || mapCandidate
      );
      this.$emit("change", emailCandidates);
    },
  },
};
</script>
