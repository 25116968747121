<template>
  <div class="avatar-group">
    <div v-for="item in items" :key="item.id" class="avatar">
      <span class="avatar-name">{{ item.name }}</span>
      <img
        :src="
          item.photo ? `${apiUrl}/images/user/${item.photo}` : `/avatar.png`
        "
        alt="Image"
      />
    </div>
  </div>
</template>

<script>
import { API_URL } from "@/api";
export default {
  name: "AvatarGroup",

  props: {
    items: { type: Array },
  },

  data: () => ({
    apiUrl: API_URL,
  }),
};
</script>

<style scoped>
.avatar-group {
  display: flex;
  align-items: center;
  justify-content: left;
}

.avatar {
  position: relative;
  transition: 0.2s;
}

.avatar:not(:first-child) {
  margin-left: -0.7rem;
}

.avatar:hover {
  z-index: 1;
  transform: translateY(-0.5rem);
}

.avatar-name {
  position: absolute;
  bottom: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 0.875rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
}

.avatar-name::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 0.5rem solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent;
}

.avatar:hover .avatar-name {
  opacity: 1;
  visibility: visible;
}

.avatar img {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}
</style>