<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card>
      <!-- <pre>{{ classification.id }}</pre> -->
      <!-- <v-alert
        v-model="alert"
        color="#fdf0d7"
        dense
        type="info"
        class="text-center black--text caption mb-0"
        dismissible
        >{{ $t("Notepad_alert") }}</v-alert
      > -->

      <v-card-title class="d-flex flex-row justify-space-between">
        <div></div>
        <div>
          <span class="headline">{{ $t("obs") }}</span>
        </div>
        <div>
          <!-- <v-btn icon>
            <v-icon small>mdi-share-variant</v-icon>
          </v-btn> -->
        </div>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" class="scroll-test mb-5">
            <div
              class="d-flex flex-column pb-2"
              v-for="note in classification.notes"
              :key="note.id"
            >
              <div>
                <span class="black--text font-weight-bold">{{
                  note.authorName
                }}</span>
                <span class="caption"> | {{ localDate(note.date) }}</span>
              </div>
              <div>
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-row class="showBtn mt-0">
                    <v-textarea
                    :value="note.text"
                    auto-grow
                    dense
                    hide-details
                    flat
                    readonly
                    solo
                    row-height="15"
                  />

                    <v-btn
                      id="btn1"
                      :class="{ 'on-hover': hover }"
                      :style="hover ? 'display:inline' : ''"
                      text
                      icon
                      @click="edit(note)"
                      x-small
                      color="primary"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn
                      id="btn2"
                      :class="{ 'on-hover': hover }"
                      :style="hover ? 'display:inline' : ''"
                      text
                      icon
                      @click="setNoteToDelete(note.id)"
                      x-small
                      color="primary"
                      ><v-icon>mdi-delete </v-icon></v-btn
                    >
                  </v-row>
                </v-hover>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <!-- <template>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="mx-1">
                  mdi-help-circle-outline</v-icon
                >
              </template>
              <div v-html="$t('tooltip_notpad_vacancy')"></div>
            </v-tooltip>
          </template> -->
          <v-textarea
            v-model="note.text"
            :label="$t('share_observations')"
            outlined
            dense
            auto-grow
            hide-details
            rows="1"
            class="pt-1"
            :readonly="disabled"
          ></v-textarea>
        </v-row>
      </v-card-text>

      <v-card-actions class="px-5">
        <v-spacer></v-spacer>
        <v-btn text small @click="close()" color="primary">{{
          $t("close")
        }}</v-btn>
        <v-btn
          :loading="isLoading"
          :disabled="isLoading || note.text === null || note.text === '' || disabled"
          color="primary"
          small
          @click="note.id ? update() : add()"
          >{{ $t("Add") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <confirm-dialog
      :dialogConfirm="dialogConfirm"
      @yes="deleteNote"
      @no="dialogConfirm.dialog = false"
    />
  </v-dialog>
</template>

<script>
import format from "date-fns/format";
import { pt, enUS } from "date-fns/locale";
import {
  ADD_NOTE_INTERVIEW_MUTATION,
  UPDATE_NOTE_INTERVIEW_MUTATION,
  REMOVE_NOTE_INTERVIEW_MUTATION,
} from "../../graphql/Mutation.gql";
import ConfirmDialog from "../ConfirmDialog.vue";
export default {
  name: "NoteInterview",

  props: {
    dialog: { type: Boolean },
    disabled: { type: Boolean },
    interviewId: { type: String },
    selectedStage: { type: String },
    selectedCandidate: { type: Object },
    classification: { type: Object },
    memberInterview: { type: Object },
  },

  components: {
    ConfirmDialog,
  },

  data: () => ({
    alert: true,
    isLoading: false,
    notes: [],
    note: {
      id: undefined,
      author: undefined,
      authorName: undefined,
      text: "",
      date: undefined,
    },
    resetNote: {
      id: undefined,
      author: undefined,
      authorName: undefined,
      text: "",
      date: undefined,
    },
    dialogConfirm: {
      dialog: false,
      question: "",
      id: null,
    },
  }),

  created() {},

  methods: {
    close() {
      this.$emit("close");
    },

    setNoteToDelete(id) {
      this.dialogConfirm.id = id;
      this.dialogConfirm.question = this.$t("interview_note_remove_question");
      this.dialogConfirm.dialog = true;
    },

    async add() {
      this.isLoading = true;
      const candidateIdOrEmail = this.getCandidateIdOrEmail();
      try {
        await this.$apollo.mutate({
          mutation: ADD_NOTE_INTERVIEW_MUTATION,
          variables: {
            interviewNoteInput: {
              ...this.memberInterview,
              interviewId: this.interviewId,
              candidateIdOrEmail,
              note: this.note,
              stage: this.selectedStage?this.selectedStage:'default',
              classificationId: this.classification.id,
            },
          },
        });
        this.note = this.resetNote;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    edit(note) {
      this.note = note;
    },

    async update() {
      this.isLoading = true;
      const candidateIdOrEmail = this.getCandidateIdOrEmail();
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_NOTE_INTERVIEW_MUTATION,
          variables: {
            interviewNoteInput: {           
              ...this.memberInterview,
              interviewId: this.interviewId,
              candidateIdOrEmail,
              note: this.note,
              stage: this.selectedStage?this.selectedStage:'default',
              classificationId: this.classification.id,
            },
          },
        });
        this.note = this.resetNote;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async deleteNote(id) {
      this.isLoading = true;
      const note = this.classification.notes.find(
        (findNote) => findNote.id === id
      );
      const candidateIdOrEmail = this.getCandidateIdOrEmail();
      try {
        await this.$apollo.mutate({
          mutation: REMOVE_NOTE_INTERVIEW_MUTATION,
          variables: {
            interviewNoteInput: {
              ...this.memberInterview,
              interviewId: this.interviewId,
              candidateIdOrEmail,
              note,
              stage: this.selectedStage?this.selectedStage:'default',
              classificationId: this.classification.id,
            },
          },
        });
        this.dialogConfirm.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getCandidateIdOrEmail() {
      return this.selectedCandidate.interviewee
        ? this.selectedCandidate.interviewee.id
        : this.selectedCandidate.email;
    },

    localDate(date) {
      const currentLang = this.$i18n.locale;

      let formattedDate;
      switch (currentLang) {
        case "en":
          formattedDate = format(new Date(date), "yyyy/MM/dd hh:mm a", {
            locale: enUS,
          });
          break;

        default:
          formattedDate = format(new Date(date), "dd/MM/yyyy HH:mm", {
            locale: pt,
          });
          break;
      }

      return formattedDate;
    },
  },
};
</script>

<style scoped>
.scroll-test {
  height: 320px;
  overflow: auto;
}
#btn1 {
  display: none;
}
#btn2 {
  display: none;
}
</style>