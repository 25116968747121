var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-combobox',{attrs:{"items":_vm.isObserver ? _vm.filteredObservers : _vm.filteredRecruters,"label":_vm.isObserver ? _vm.$t('observers') : _vm.$t('recruters'),"chips":"","item-text":"fullName","item-value":"id","multiple":"","dense":"","outlined":"","hide-details":"","required":"","rules":_vm.isObserver ? [] : [_vm.required]},on:{"change":function($event){return _vm.$emit('change', _vm.recruters)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeRecruter(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":"","size":"24"}},[_c('v-img',{attrs:{"src":data.item.photo
              ? `${_vm.apiUrl}/images/user/${data.item.photo}`
              : `/avatar.png`}})],1),_vm._v(" "+_vm._s(data.item.name.split(" ")[0])+" "+_vm._s(data.item.name.split(" ")[data.item.name.split(" ").length - 1])+" ")],1)]}},{key:"item",fn:function(data){return [[_c('v-list-item-avatar',{attrs:{"size":"24"}},[_c('img',{attrs:{"src":data.item.photo
              ? `${_vm.apiUrl}/images/user/${data.item.photo}`
              : `/avatar.png`}})]),(data.item.name)?_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
            `${data.item.name.split(' ')[0]} ${
              data.item.name.split(' ')[data.item.name.split(' ').length - 1]
            }`
          )}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.office)}})],1):_vm._e()]]}}]),model:{value:(_vm.recruters),callback:function ($$v) {_vm.recruters=$$v},expression:"recruters"}})
}
var staticRenderFns = []

export { render, staticRenderFns }