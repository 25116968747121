<template>
  <div class="whirelab-results">
    <div class="whirelab-results-card">
      <!-- <div
        class="d-flex justify-end align-center mb-2"
        style="cursor: pointer"
        @click="openNotes()"
      >
        <v-badge
          :content="selectedCandidate.notes.length"
          :value="selectedCandidate.notes.length"
          bordered
          color="primary"
          left
          overlap
        >
          -- <v-btn icon> --
          <div class="d-flex align-center">
            <div><v-icon>mdi-folder-outline</v-icon></div>
          </div>
          <-- </v-btn> --
        </v-badge>
        <div class="px-2 primary--text" style="font-size: 14px">
          {{ $t("Notepad") }}
        </div>
      </div> -->

      <div v-if="selectedCandidate.interviewee">
        <v-btn
          text
          small
          color="primary"
          class="px-0 mb-2"
          target="_blank"
          :to="`/pdf/${selectedCandidate.interviewee.id}`"
        >
          <v-icon class="pl-0 ml-0">mdi-file-account-outline</v-icon>
          <span>{{ $t("view_cv") }}</span>
        </v-btn>
      </div>

      <div v-if="isInterviewer" class="mb-2">
        <div style="font-size: 15px">
          {{ $t("phases_interview") }}
        </div>
      </div>

      <div class="whirelab-results-stages mb-2">
        <div
          v-for="(stage, index) in stages"
          :key="stage.name"
          class="whirelab-results-stages-content"
          :class="{ 'text--disabled': !isStageActivate(stage.name) }"
        >
          <div class="whirelab-results-stage-labels">
            <div
              class="whirelab-results-stage-labels-text"
              :class="{
                'whirelab-active': isSelectedStage(stage.name),
                'text--disabled': !isStageActivate(stage.name),
              }"
              @click="selectStage(stage)"
            >
              <div>{{ index + 1 }}º {{ stage.name }}</div>
            </div>
          </div>
          <div class="whirelab-results-stage-values">
            <div class="stage-duration">{{ $t("duration") }}:</div>
            <div :class="{ 'font-weight-black': isCurrentStage(stage.name) }">
              {{ isCurrentStage(stage.name) ? time : stage.duration }}
            </div>
            <div v-if="!isCompleted && isInterviewer">
              <v-icon
                small
                class="mx-1"
                :disabled="isRunning"
                @click="setStageToEdit(stage.name)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                :disabled="isRunning"
                @click="setStageToRemove(stage.name)"
                >mdi-delete</v-icon
              >
            </div>
          </div>
        </div>
      </div>

      <div class="whirelab-results-rating mb-2">
        <div class="whirelab-results-rating-label">
          {{ $t("salary_expectation") }}
        </div>
        <div class="whirelab-results-rating-value">
          {{ selectedCandidate.salary }} - {{ selectedCandidate.maxSalary }}
        </div>
      </div>

      <v-divider dark></v-divider>
      <div v-if="isInterviewer" class="mb-2 mt-0">
        <div style="font-size: 13px; color: black">
          {{ $t("specific_rating") }}
        </div>
      </div>
      <div
        class="whirelab-results-rating mb-2"
        v-for="(classification, c) in questions"
        :key="classification.id"
      >
        <div class="whirelab-results-rating-label">
          {{ c + 1 + $t("sequence") }} {{ $t("question") }}
        </div>
        <div class="whirelab-results-rating-value">
          <v-progress-linear
            :value="getSpecificRatingInQuestion(classification.id).toFixed(2)"
            readonly
            height="18"
            :color="
              thumbColorClassification(
                getSpecificRatingInQuestion(classification.id).toFixed(2)
              )
            "
          >
            <span class="black--text"
              >{{
                getSpecificRatingInQuestion(classification.id).toFixed(2)
              }}%</span
            >
          </v-progress-linear>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="whirelab-results-rating mb-2 mt-1">
        <div
          class="whirelab-results-rating-label"
          style="font-size: 13px; color: black"
        >
          {{ $t("overall_classification") }}
        </div>
        <div class="whirelab-results-rating-value">
          <v-progress-linear
            :value="rating"
            readonly
            height="18"
            :color="thumbColor"
          >
            <span class="black--text">{{ rating.toFixed(2) }}%</span>
          </v-progress-linear>
        </div>
      </div>
    </div>
    <confirm-dialog
      :dialogConfirm="dialogConfirm"
      @yes="deleteStage"
      @no="dialogConfirm.dialog = false"
    />

    <notes
      :dialog="noteDialog"
      :interviewId="interviewId"
      :selectedCandidate="selectedCandidate"
      :selectedStage="selectedStage"
      :memberInterview="memberInterview"
      @close="noteDialog = false"
    />

    <progress-dialog :processing="isLoading" />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import {
  UPDATE_SALARY_INTERVIEW_MUTATION,
  UPDATE_STAGE_INTERVIEW_MUTATION,
  ADD_STAGE_INTERVIEW_MUTATION,
  REMOVE_STAGE_INTERVIEW_MUTATION,
} from "../../../graphql/Mutation.gql";
import ConfirmDialog from "../../ConfirmDialog.vue";
import Notes from "../Notes.vue";
import { mapGetters } from "vuex";
export default {
  name: "Results",

  components: {
    ConfirmDialog,
    Notes,
  },

  props: {
    interviewId: { type: String },
    interviews: { type: Array },
    selectedCandidate: { type: Object },
    selectedRecruter: { type: Object },
    selectedStage: { type: String },
    isCompleted: { type: Boolean },
    isRunning: { type: Boolean },
    isInterviewer: { type: Boolean },
    isRunningButNotJoined: { type: Boolean },
  },

  data: () => ({
    apiUrl: API_URL,
    knowledge: 40,
    totalSages: 0,
    stageDialog: false,
    salary: null,
    salaryDiabled: true,
    oldStageName: "",
    newStage: "",
    stage: {
      name: "",
    },
    dialogConfirm: {
      dialog: false,
      question: "",
      id: null,
    },
    time: undefined,
    tempo: 1000,
    hh: undefined,
    mm: undefined,
    ss: undefined,
    noteDialog: false,
    isLoading: false,
    member: undefined,
  }),

  watch: {
    selectedCandidate(newVal) {
      this.totalSages = newVal.stages.length;
      // this.selectedStage = newVal.currentStage;
      const currentStage = newVal.stages.find(
        (findStage) => findStage.name === newVal.currentStage
      );
      if (currentStage.state === "running") {
        clearInterval(this.cron);
        this.cron = setInterval(() => {
          this.timer();
        }, this.tempo);
      } else {
        clearInterval(this.cron);
        this.time = currentStage.duration;
      }
    },
  },

  created() {
    const currentStage = this.selectedCandidate.stages.find(
      (findStage) => findStage.name === this.selectedCandidate.currentStage
    );
    this.totalSages = this.selectedCandidate.stages.length;
    this.time = currentStage.duration;
    const currentTimer = this.time.split(":");
    this.hh = Number(currentTimer[0]);
    this.mm = Number(currentTimer[1]);
    this.ss = Number(currentTimer[2]);
    this.member = {
      ...this.selectedRecruter.user,
      isObserver: this.selectedRecruter.isObserver,
    };
  },
  mounted() {
    this.member = {
      ...this.selectedRecruter.user,
      isObserver: this.selectedRecruter.isObserver,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    stages() {
      return this.selectedCandidate.stages;
    },
    memberInterview() {
      return {
        evaluatorType: this.member.isObserver ? "observer" : "recruter",
        evaluatorID: this.currentUser
          ? this.member.id === this.currentUser.id
            ? this.member.id
            : undefined
          : undefined,
      };
    },
    specificRating() {
      const stage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedStage
      );

      return stage;
    },
    questions() {
      return this.selectedRecruter.classification;
    },
    rating() {
      const INIT_CLASSIFICATION_SUM = 0;
      // const FIRST_ELEMENT = 0;
      let resultRecruters = 0;
      let resultObservers = 0;
      const stage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedStage
      );

      // const TOTAL_CLASSIFICATION_IN_STAGE =
      //   stage.recruters[FIRST_ELEMENT].classification.length;
      const TOTAL_RECRUTERS = stage.recruters.length;
      const TOTAL_OBSERVERS = stage.observers.length || 0;

      stage.recruters.forEach((findRecruter) => {
        resultRecruters += findRecruter.classification.reduce(
          (accumulate, findClassificatiom) => {
            const points = findClassificatiom.points || 0;
            // const percentageWeight = findClassificatiom.percentageWeight || 0;
            return accumulate + points;
            // return accumulate + points * (percentageWeight / 100);
          },
          INIT_CLASSIFICATION_SUM
        );
      });

      if (stage.observers.length > 0) {
        stage.observers.forEach((findObserver) => {
          resultObservers += findObserver.classification.reduce(
            (accumulate, findClassificatiom) => {
              const { points } = findClassificatiom;
              // const { points, percentageWeight } = findClassificatiom;
              return accumulate + points;
              // return accumulate + points * (percentageWeight / 100);
            },
            INIT_CLASSIFICATION_SUM
          );
        });
      }
      return (
        (resultRecruters + resultObservers) /
        (TOTAL_RECRUTERS + TOTAL_OBSERVERS)
      );
    },

    thumbColor() {
      if (this.rating < 40) return "red";
      if (this.rating < 50) return "orange";
      if (this.rating < 75) return "indigo";
      if (this.rating < 100) return "teal";
      return "green";
    },
  },

  methods: {
    openNotes() {
      this.noteDialog = true;
    },
    getSpecificRatingInQuestion(id) {
      let totalObserver = 0;
      let sumObserver = 0;
      let totalRecruters = 0;
      let sumRecruters = 0;
      let sum = 0;
      this.specificRating.observers.forEach((ob) => {
        sumObserver += parseFloat(
          ob.classification.find((q) => q.id === id).points
        );
        totalObserver += 1;
      });

      this.specificRating.recruters.forEach((ob) => {
        sumRecruters += parseFloat(
          ob.classification.find((q) => q.id === id).points
        );
        totalRecruters += 1;
      });

      sum = (sumObserver + sumRecruters) / (totalObserver + totalRecruters);
      return sum;
    },
    thumbColorClassification(rating) {
      if (rating < 40) return "red";
      if (rating < 50) return "orange";
      if (rating < 75) return "indigo";
      if (rating < 100) return "teal";
      return "green";
    },
    cancelUpdateStage() {
      this.stageDialog = false;
    },

    setStageToEdit(stage) {
      this.stage.name = stage;
      this.oldStageName = stage;
      this.stageDialog = true;
    },

    setStageToRemove(stage) {
      this.dialogConfirm.id = stage;
      this.dialogConfirm.question = this.$t("interview_stage_remove_question");
      this.dialogConfirm.dialog = true;
    },

    async updateStage() {
      this.isLoading = true;
      const candidateIds = this.getCandidateIdsFromInterviews();
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_STAGE_INTERVIEW_MUTATION,
          variables: {
            interviewUpdateStageInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              oldStageName: this.oldStageName,
              newStageName: this.stage.name,
            },
          },
        });
        this.stageDialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async changeSalary() {
      if (this.salaryDiabled) {
        this.salaryDiabled = false;
      } else {
        const candidateIdOrEmail = this.getSelectedCandidateIdOrEmail();
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_SALARY_INTERVIEW_MUTATION,
            variables: {
              interviewUpdateSalaryInput: {
                interviewId: this.interviewId,
                candidateIdOrEmail,
                salary: this.selectedCandidate.salary,
              },
            },
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.salaryDiabled = true;
        }
      }
    },

    async addNewStage() {
      const candidateIds = this.getCandidateIdsFromInterviews();
      const nextStageIndex = this.stages.length + 1;
      try {
        await this.$apollo.mutate({
          mutation: ADD_STAGE_INTERVIEW_MUTATION,
          variables: {
            interviewAddStageInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              stage: `default ${nextStageIndex}`,
            },
          },
        });
        this.newStage = "";
      } catch (error) {
        console.log(error);
      }
    },

    async deleteStage(stage) {
      this.isLoading = true;
      const candidates = this.getCandidateIdsFromInterviews();
      try {
        await this.$apollo.mutate({
          mutation: REMOVE_STAGE_INTERVIEW_MUTATION,
          variables: {
            interviewAddStageInput: {
              interviewId: this.interviewId,
              candidates,
              stage,
            },
          },
        });
        this.dialogConfirm.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    selectStage(stage) {
      if (this.isStageActivate(stage.name)) {
        this.$emit("chageStage", stage);
      }
    },

    isStageActivate(stage) {
      const findedStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === stage
      );

      return findedStage.recruters && findedStage.recruters.length > 0;
    },

    getSelectedCandidateIdOrEmail() {
      return this.selectedCandidate.interviewee
        ? this.selectedCandidate.interviewee.id
        : this.selectedCandidate.email;
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },

    isCurrentStage(stage) {
      return this.selectedCandidate.currentStage === stage;
    },

    isSelectedStage(stage) {
      return this.selectedStage === stage;
    },

    timer() {
      this.ss++; //Incrementa +1 na variável ss

      if (this.ss == 59) {
        //Verifica se deu 59 segundos
        this.ss = 0; //Volta os segundos para 0
        this.mm++; //Adiciona +1 na variável mm

        if (this.mm == 59) {
          //Verifica se deu 59 minutos
          this.mm = 0; //Volta os minutos para 0
          this.hh++; //Adiciona +1 na variável hora
        }
      }

      //Cria uma variável com o valor tratado HH:MM:SS
      var format =
        (this.hh < 10 ? "0" + this.hh : this.hh) +
        ":" +
        (this.mm < 10 ? "0" + this.mm : this.mm) +
        ":" +
        (this.ss < 10 ? "0" + this.ss : this.ss);

      //Insere o valor tratado no elemento counter
      this.time = format;

      //Retorna o valor tratado
      return format;
    },
  },
};
</script>

<style scoped>
.whirelab-results {
  background-color:#ccebe9;
  padding: 20px;
  border-radius: 10px;
}

.whirelab-results-stages-content {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.whirelab-results-stage-labels {
  width: 50%;
  cursor: pointer;
  padding: 1px 5px;
}

.whirelab-results-stage-labels-text {
  padding: 0 4px;
}

.whirelab-results-stage-values {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.whirelab-results-rating {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.whirelab-results-rating-label {
  width: 50%;
  padding: 0 10px;
}

.whirelab-results-rating-value {
  width: 50%;
}

.whirelab-results-salary {
  font-size: 12px;
}

.whirelab-results-salary-content {
  display: flex;
  align-items: center;
}

.whirelab-results-salary-label {
  width: 50%;
  padding: 0 10px;
}

.whirelab-results-salary-value {
  display: flex;
  align-items: center;
  width: 50%;
}

.v-text-field >>> label {
  font-size: 13px;
  margin-top: -4px;
}

.input-salary.v-text-field >>> label {
  font-size: 16px;
  margin-top: -6px;
}

.input-salary.v-text-field >>> input {
  font-size: 12px;
  margin-top: -4px;
  margin-bottom: 2px;
}

.whirelab-results-salary-input {
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  /* border-radius: 4px; */
  margin-right: 5px;
}

.whirelab-results-salary-input:focus-within {
  outline: none !important;
  border: 1px solid #459c97 !important;
}

.whirelab-active {
  background: #459c97;
  color: #ffffff;
  border-radius: 5px;
}
</style>