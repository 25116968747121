<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-5">
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="reSchedule()"
        >
          <v-row justify="center" no-gutters>
            <v-alert
              v-model="alert"
              color="#fdf0d7"
              dense
              type="info"
              class="text-center black--text caption mb-0"
              dismissible
              >{{ $t("rescheduleformtextalert") }}</v-alert
            >
          </v-row>
          <v-card-text>
            <div align="center" class="pt-2">
              <p>{{ $t("rescheduleinter") }}</p>
            </div>

            <v-row>
              <v-col cols="12">
                <candidate-combobox
                  :candidacies="candidacies"
                  :currentCandidates="candidates"
                  @change="setCandidates"
                />
              </v-col>
              <v-col cols="12">
                <recruter-combobox
                  :existingRecruters="recruters"
                  :existingObservers="observers"
                  :isObserver="false"
                  :currentRecruters="recruters"
                  @change="changeRecruters"
                />
              </v-col>
              <v-col cols="12">
                <recruter-combobox
                  :existingRecruters="recruters"
                  :existingObservers="observers"
                  :isObserver="true"
                  :currentRecruters="observers"
                  @change="changeObservers"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="subject"
                  :label="$t('subject')"
                  dense
                  outlined
                  hide-details
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <date-picker :currentDate="date" @input="setDate" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="time"
                  :label="$t('interview_time')"
                  v-mask="'##:##'"
                  placeholder="00:00"
                  prepend-inner-icon="access_time"
                  dense
                  outlined
                  hide-details
                  :rules="timeRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="address"
                  :label="$t('phisic_adress')"
                  dense
                  prepend-inner-icon="place"
                  outlined
                  hide-details
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="telephone"
                  :label="$t('Institution_telephone')"
                  dense
                  prepend-inner-icon="mdi-phone"
                  outlined
                  hide-details
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="message"
                  rows="3"
                  :autocomplete="$t('enter_message')"
                  :label="$t('enter_message')"
                  :hint="$t('interviewCreateSuccesshint')"
                  persistent-hint
                  dense
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()" color="primary">
              {{ $t("finish") }}
            </v-btn>
            <v-btn
              class="mr-4 px-6"
              color="primary"
              type="submit"
              :loading="isLoading"
            >
              {{ $t("send") }}
            </v-btn>
            <v-btn
              absolute
              top=""
              right=""
              icon=""
              class="mt-5"
              @click="$emit('close')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import {
  RE_SCHEDULE_INTERVIEW_MUTATION,
  SCHEDULE_NEXT_STAGE_INTERVIEW_MUTATION,
} from "../graphql/Mutation.gql";
import CandidateCombobox from "./form/CandidateCombobox.vue";
import RecruterCombobox from "./form/RecruterCombobox.vue";
import DatePicker from "./form/DatePicker.vue";
export default {
  name: "ReScheduleInterview",

  components: {
    CandidateCombobox,
    RecruterCombobox,
    DatePicker,
  },

  data: () => ({
    apiUrl: API_URL,
    alert: true,
    dialog: false,
    candidates: [],
    candidacies: [],
    interviews: [],
    recruters: [],
    observers: [],
    subject: "",
    date: null,
    time: null,
    address: "",
    telephone: "",
    message: "",
    stage: "",
    interviewId: null,
    isCurrentStage: true,
    valid: true,
    isLoading: false,
  }),

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on(
      "ReScheduleInterview",
      ({ interviews, stage, interviewId, isCurrentStage }) => {
        this.resetForm();
        this.candidacies = interviews.map((mapInterview) => {
          return mapInterview.interviewee
            ? mapInterview.interviewee
            : { fullName: mapInterview.email };
        });

        this.isCurrentStage = isCurrentStage;

        this.interviews = interviews;
        this.interviewId = interviewId;

        this.setDefault(interviews, stage);

        this.stage = stage;
        this.dialog = true;
      }
    );
  },

  computed: {
    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
    timeRules() {
      return [
        (v) => !!v || this.$t("form_user_valid"),
        (v) => {
          const slited = v.split(":");
          return (
            (slited[0] < 24 && slited[1] < 60) || this.$t("form_user_valid")
          );
        },
      ];
    },
  },

  methods: {
    async reSchedule() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const candidates = this.getCandidateIdsFromInterviews();
        const recruters = this.getRecruterIds();
        const observers = this.getObserverIds();
        try {
          await this.$apollo.mutate({
            mutation: this.isCurrentStage
              ? RE_SCHEDULE_INTERVIEW_MUTATION
              : SCHEDULE_NEXT_STAGE_INTERVIEW_MUTATION,
            variables: {
              interviewReScheduleInput: {
                interviewId: this.interviewId,
                candidates,
                recruters,
                observers,
                date: this.date,
                time: this.time,
                subject: this.subject,
                telephone: this.telephone,
                message: this.message,
                address: this.address,
                stage: this.stage,
              },
            },
          });
          const successMsg = this.$t("interview_scheduled_successfully");
          // eslint-disable-next-line no-undef
          Fire.$emit("SuccessDialog", successMsg);
          if (this.interviews.length === 1) {
            this.dialog = false;
          }
          this.resetForm();
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    resetForm() {
      this.candidates = [];
      this.recruters = [];
      this.observers = [];
      this.date = null;
      this.time = null;
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.candidates.map((mapCandidate) => {
        return mapCandidate.id ? mapCandidate.id : mapCandidate.fullName;
      });

      return candidates;
    },

    getRecruterIds() {
      return this.recruters.map((mapRecruter) => mapRecruter.id);
    },

    getObserverIds() {
      return this.observers.map((mapObserver) => mapObserver.id);
    },

    setCandidates(candidates) {
      this.candidates = candidates;
    },

    close() {
      this.resetForm();
      this.dialog = false;
    },

    changeRecruters(recruters) {
      this.recruters = recruters;
    },

    changeObservers(observers) {
      this.observers = observers;
    },

    setDate(date) {
      this.date = date;
    },

    setDefault(interviews, stage) {
      const FIRST_ELEMENT = 0;
      const FIRST_INTERVIEW = this.interviews[FIRST_ELEMENT];

      const findedStageIndex = FIRST_INTERVIEW.stages.findIndex(
        (findStage) => findStage.name === stage
      );
      this.recruters = FIRST_INTERVIEW.stages[findedStageIndex].recruters.map(
        (mapRecruter) => mapRecruter.user
      );

      this.observers = FIRST_INTERVIEW.stages[findedStageIndex].observers.map(
        (mapObserver) => mapObserver.user
      );

      this.subject = FIRST_INTERVIEW.subject;
      this.telephone = FIRST_INTERVIEW.telephone;
      this.address = FIRST_INTERVIEW.address;
      this.message = FIRST_INTERVIEW.message;
    },
  },
};
</script>