<template>
    <v-container class="fill-heigt" fluid>
      <v-row>
        <!-- <pre>{{ interviews }}</pre> -->
        <v-expansion-panels>
          <template v-for="intervieww in interviews.outstanding">
            <expansion-panel
              :interviewId="interview_id"
              :interviews="intervieww"
              :vacancyId="interview.vacancy.id"
              :key="intervieww.timestemp"
            />
          </template>
        </v-expansion-panels>
      </v-row>
  
      <add-recruter-dialog />
      <add-observer-dialog />
      <re-schedule-interview />
      <finish-interview />
      <cancel-interview />
      <edit-email-dialog />
      <success-dialog />
      <options-re-schedule />
      <add-candidate-to-interview @added="addCandidate" />
    </v-container>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { GET_INTERVIEW_BY_ID_QUERY } from "../../../../../interview/graphql/Query.gql";
  import { UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION } from "../../../../../interview/graphql/Subscraption.gql";
  import { groupBy } from "@/utils";
  import ExpansionPanel from "./interviewListPanel.vue";
  import AddRecruterDialog from "../../../../../interview/components/AddRecruterDialog.vue";
  import AddObserverDialog from "../../../../../interview/components/AddObserverDialog.vue";
  import ReScheduleInterview from "../../../../../interview/components/ReScheduleInterview.vue";
  import FinishInterview from "../../../../../interview/components/FinishInterview.vue";
  import CancelInterview from "../../../../../interview/components/CancelInterview.vue";
  import EditEmailDialog from "../../../../../interview/components/EditEmailDialog.vue";
  import SuccessDialog from "../../../../../interview/components/SuccessDialog.vue";
  import OptionsReSchedule from "../../../../../interview/components/OptionsReSchedule";
  import AddCandidateToInterview from "../../../../../interview/components/form/AddCandidateToInterview.vue";
  import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
  
  export default {
    name: "interviewList",
    mixins: [replaceSpecialCharsMixins],
    components: {
      ExpansionPanel,
      AddRecruterDialog,
      AddObserverDialog,
      ReScheduleInterview,
      FinishInterview,
      CancelInterview,
      EditEmailDialog,
      SuccessDialog,
      OptionsReSchedule,
    //   WarningDialog,
      AddCandidateToInterview,
    },
  
    data: () => ({
      interview: {},
      text: "",
      alert: true,
      dialogWarning: false,
      joined: false,
      pageInterview: "",
    }),
    props:["interview_id","candidate_id"],
    apollo: {
      interview: {
        query: GET_INTERVIEW_BY_ID_QUERY,
        variables() {
          return { id: this.interview_id };
        },
        fetchPolicy: "cache-and-network",
      },
      $subscribe: {
        updateInterviewSchedule: {
          query: UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION,
          result({ data }) {
            this.interview = data.updateInterviewSchedule;
          },
        },
      },
    },
  
    created() {
      // eslint-disable-next-line no-undef
      Fire.$on("joined", () => {
        this.joined = true;
      });
      // eslint-disable-next-line no-undef
      Fire.$on("left", () => {
        this.joined = false;
      });
    },
  
    beforeRouteLeave(to, from, next) {
      if (this.joined) {
        // eslint-disable-next-line no-undef
        this.dialogWarning = true;
        next(false);
      } else {
        next(true);
      }
    },
  
    computed: {
      ...mapGetters({
        getBlockedCandidates: "candidate/getBlockedCandidates",
      }),
      selectInterview() {
        return [
          {
            key: "concluded_canceled_interviews",
            text: this.$t("concluded_canceled_interviews"),
          },
          { key: "approved_candidates", text: this.$t("approved_candidates") },
        ];
      },
      interviews() {
        const ZERO_NUMBER = 0;
        const blockeds = this.getBlockedCandidates;
        const candidates = this.interview.candidates;
        let list = candidates
          ? candidates.map((findCandidate) => {
              const findIndex = blockeds.findIndex(
                (blockedId) =>
                  findCandidate.interviewee &&
                  blockedId == findCandidate.interviewee.id
              );
              const stage = findCandidate.stages.find(
                (findStage) => findStage.name === findCandidate.currentStage
              );
              return {
                ...findCandidate,
                address: this.interview.address,
                subject: this.interview.subject,
                telephone: this.interview.telephone,
                message: this.interview.message,
                vacancy: this.interview.vacancy.title,
                timestemp: `${stage.date} ${stage.time} ${stage.groupBy}`,
                blocked: findIndex >= ZERO_NUMBER,
              };
            })
          : [];
  
        const completed = list.filter(
          (filterCandidate) =>
            filterCandidate.status === "rejected" ||
            filterCandidate.status === "finished" ||
            filterCandidate.status === "canceled"
        );
  
        list = list.filter(
          (filterCandidate) =>
            filterCandidate.status !== "approved" &&
            filterCandidate.status !== "rejected" &&
            filterCandidate.status !== "finished" &&
            filterCandidate.status !== "canceled"
        );
  
        if (this.text) {
          const text = this.replaceSpecialChars(this.text);
          list = list.filter((c) =>
            this.replaceSpecialChars(c.interviewee.fullName).includes(text)
          );
        }
  
        list = list.filter(l => {
           return l.interviewee.id === this.candidate_id
        });
        const outstanding = groupBy(list, "timestemp", (record, dateKey) => {
          return [record[dateKey]];
        });
  
        let resultItems = {outstanding, completed };
  
        resultItems.outstanding = groupBy(
          list,
          "timestemp",
          (record, dateKey) => {
            return [record[dateKey]];
          }
        );
  
        return resultItems;
      },
    },
  
    methods: {
      addCandidate(candidates) {
        this.interview.candidates = candidates;
      },
  
      openScheduleInterviewDialog() {
        // eslint-disable-next-line no-undef
        Fire.$emit("AddCandidateToInterview", this.interview);
      },
  
      go() {
        this.$router.push({
          name: "completed.interviews",
          params: {
            id: this.$route.params.id,
          },
        });
      },
      selectInterviewPage() {
        console.log(this.pageInterview);
        if (this.pageInterview === "concluded_canceled_interviews") {
          //this.go();
        }
        if (this.pageInterview === "approved_candidates") {
        //   this.$router.push({
        //     name: "approved.interviews",
        //     params: {
        //       id: this.$route.params.id,
        //     },
        //   });
        }
      },
      goBack() {
        // this.$router.push({
        //   name: "interviews.preview",
        //   params: {
        //     id: this.$route.params.id,
        //   },
        // });
        this.$router.push({
          name: "interviews",
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .whirelab-conclud-folder {
    position: relative;
    display: inline-block;
  }
  
  .whirelab-conclud-folder .whirelab-conclud-tooltip {
    visibility: hidden;
    width: 280px;
    background-color: #757575;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 14px;
    padding: 5px 5px;
  
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    margin-left: -120px;
  }
  
  .whirelab-conclud-folder:hover .whirelab-conclud-tooltip {
    visibility: visible;
  }
  </style>
  