<template>
  <v-dialog v-model="dialog" persistent max-width="550px">
    <v-card class="pb-2">
      <v-row>
        <v-spacer></v-spacer>
        <v-btn icon class="mt-2 mx-3" color="primary" @click="$emit('close')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-row>
      <div class="text-center">
        <br />
        <p class="mx-2">{{ $t("aprove_or_reject_candidates") }}</p>
      </div>

      <v-card-text>
        <!-- <pre>{{ selectedCandidates }}</pre> -->
        <!-- <span class="mx-4">{{ $t("to") }}:</span> -->
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-4 mb-2 align-center px-6" style="margin-top: -3%">
            <template v-for="candidate in selectedCandidates">
              <candidate-chip :candidate="candidate" :key="candidate.id" />
            </template>
          </v-row>
          <v-divider class="my-2"></v-divider>

          <v-row align="center">
            <!-- <span class="mx-4 py-6 px-3" style="margin-bottom:-7%">Recrutadores:</span> -->
            <!-- <v-col cols="12" md="12" class="px-6"> -->
            <!-- <pre>{{ vacancy.stages }}</pre> -->
            <v-col cols="12" md="12">
              <v-select
                v-model="stage"
                :items="vacancy.stages"
                :label="$t('select_the_stage')"
                small-chips
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                hide-details
                required
                :rules="requiredRules"
                @change="getStage()"
              >
              </v-select>
            </v-col>
            <!-- <v-col cols="12" md="12" class="px-6"> -->

            <!-- <v-col cols="12" md="12">
              <v-textarea
                v-model="interview.message"
                rows="3"
                :label="$t('enter_message')"
                :hint="$t('interviewCreateSuccesshint')"
                persistent-hint
                dense
                outlined
              ></v-textarea>
            </v-col> -->
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-2 px-3 ">
            <v-btn text small class="mx-2" @click="reprove()" color="primary">{{
              $t("reject")
            }}</v-btn>
            <v-btn
              :disabled="isLoading"
              color="primary"
              small
              @click="send()"
              dense
              >{{ $t("approve") }}</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
// import { SCHEDULE_INTERVIEW_MUTATION } from "../graphql/Mutation.resolver";
import { GET_USERS_ENTITY_QUERY } from "../graphql/Query.resolver";
import { formatErrors } from "@/utils";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import moment from "moment";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import CandidateChip from "../../../components/CandidateChip.vue";
import Interview from "@/models/Interview";
import { UPDATE_STATUS_STAGE_MUTATION } from "../graphql/Mutation.resolver";
export default {
  name: "ApproveReject",
  components: { ErrorDialog, ProgressDialog, SuccessDialog, CandidateChip },
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
    selectedCandidates: Array,
    vacancyId: String,
    client: String,
    vacancy: Object,
  },
  data: () => ({
    apiUrl: API_URL,
    interview: new Interview(),
    menu: false,
    menu2: false,
    usersLocal: [],
    valid: true,
    stage: "",
  }),
  apollo: {
    users: {
      query: GET_USERS_ENTITY_QUERY,
      fetchPolicy: "no-cache",
    },
  },
  watch: {
    dialog() {
      this.interview = new Interview();
    },
  },
  computed: {
    filteredRecruters() {
      let resultItems = this.users;
      if (this.interview.observers && this.interview.observers.length > 0) {
        resultItems = resultItems.filter((recruter) => {
          const index = this.interview.observers.findIndex(
            (guest) => guest.id == recruter.id
          );
          return index === -1;
        });
      }

      return resultItems;
    },
    filteredGuests() {
      let resultItems = this.users;
      if (this.interview.recruters && this.interview.recruters.length > 0) {
        resultItems = resultItems.filter((observer) => {
          const index = this.interview.recruters.findIndex(
            (recruter) => recruter.id == observer.id
          );
          return index === -1;
        });
      }

      return resultItems;
    },
    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
  },
  methods: {
    async feedback() {
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_STATUS_STAGE_MUTATION,
          variables: {
            vacancyId: this.vacancy.id,
            stageName: this.stage.name,
            candidates: this.selectedCandidates.map((candidate) => {
              return candidate.id;
            }),
            status: "aprove",
            message: "",
          },
        });
        this.$emit("fetch");
        this.$emit("close");
        let successMsg = this.$t("candidate_stage_approved_successfully");
        // eslint-disable-next-line no-undef
        Fire.$emit("SuccessDialog", successMsg);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    getStage() {
      this.$emit("getStage", this.stage);
    },
    dateFormat() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    removeGuest(item) {
      const index = this.interview.observers.indexOf(item);
      if (index >= 0) this.interview.observers.splice(index, 1);
    },
    removeRecruter(item) {
      const index = this.interview.recruters.indexOf(item);
      if (index >= 0) this.interview.recruters.splice(index, 1);
    },
    reprove() {
      if (this.$refs.form.validate()) {
        this.$emit("reprove");
      }
    },
    async send() {
      if (this.$refs.form.validate()) {
        try {
          this.feedback();
        } catch (error) {
          console.log(error);
          // console.log({ format: error.message.split(":")[3].split(",")[0] });

          this.error = formatErrors(error.message);

          this.interview.recruters = undefined;
          this.interview.observers = undefined;
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
