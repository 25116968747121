<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="800" persistent transition="dialog-top-transition" >
      <cv :candidate_cv="candidate" @back="$emit('close')" @openShareDialog="openShareDialog" />
    </v-dialog>
  </v-row>
</template>
<script>
import cv from "./../views/CV.vue"
export default {
  props: ["dialog", "candidate"],
  components: {
    cv,
  },
  data() {
    return {
      // dialog: false,
    }
  },
  methods: {
    openShareDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareCandidateCV", this.candidate.id);
    },
  }
}
</script>