<template>
  <div>
    <v-row class="d-flex flex-column pl-4 mt-5">
      <div class="text-capitalize">{{ $t("recruters") }}</div>
      <div class="d-flex flex-row">
        <div class="">
          <v-badge
            v-for="(recruter, index) in recruters"
            :key="recruter.user.id"
            :content="recruter.comments.length"
            :value="recruter.comments.length"
            bordered
            color="red"
            overlap
            class="my-2"
            :class="{ 'mx-3': index % 2 === 0 }"
          >
            <v-chip
              :color="
                selectedRecruter.user.id === recruter.user.id ? 'primary' : ''
              "
              label
              :close="!isRunning && isInterviewer"
              @click="selectRecruter(recruter)"
              @click:close="setRecruterToDelete(recruter.user.id)"
            >
              <v-avatar>
                <v-img
                  :src="
                    recruter.user.photo
                      ? `${apiUrl}/images/user/${recruter.user.photo}`
                      : `/avatar.png`
                  "
                ></v-img>
              </v-avatar>
              <div class="recruter-name ml-1" style="width: 65px">
                <div class="width: 100%">
                  {{ recruterName(recruter) }}
                </div>
              </div>
            </v-chip>
          </v-badge>
          <v-btn
            v-if="!isCompleted && isInterviewer"
            v-show="!isRunning"
            icon
            color="primary"
            class="mx-1"
            @click="openAddRecruterDialog()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
    </v-row>
    <v-row class="d-flex flex-column pl-4 mt-5">
      <div class="text-capitalize">{{ $t("observers") }}</div>
      <div class="d-flex flex-row">
        <div class="">
          <v-badge
            v-for="(observer, index) in observers"
            :key="observer.user.id"
            :content="observer.comments.length"
            :value="observer.comments.length"
            bordered
            color="red"
            overlap
            class="my-2"
            :class="{ 'mx-3': index % 2 === 0 }"
          >
            <v-chip
              :color="
                selectedRecruter.user.id === observer.user.id ? 'primary' : ''
              "
              :close="!isRunning && isInterviewer"
              label
              @click="selectRecruter(observer)"
              @click:close="setObserverToDelete(observer.user.id)"
            >
              <v-avatar>
                <v-img
                  :src="
                    observer.user.photo
                      ? `${apiUrl}/images/user/${observer.user.photo}`
                      : `/avatar.png`
                  "
                ></v-img>
              </v-avatar>
              <div class="recruter-name ml-1" style="width: 65px">
                <div class="width: 100%">
                  {{ recruterName(observer) }}
                </div>
              </div>
            </v-chip>
          </v-badge>
          <v-btn
            v-if="!isCompleted && isInterviewer"
            v-show="!isRunning"
            icon
            color="primary"
            class="mx-1"
            @click="openAddObserverDialog()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
    </v-row>

    <confirm-dialog
      :dialogConfirm="dialogConfirm"
      @yes="yesDialog"
      @no="dialogConfirm.dialog = false"
    />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import {
  REMOVE_RECRUTER_FROM_INTERVIEW_MUTATION,
  REMOVE_OBSERVER_FROM_INTERVIEW_MUTATION,
} from "../../graphql/Mutation.gql";
import ConfirmDialog from "../ConfirmDialog.vue";
export default {
  name: "RecruterList",

  components: {
    ConfirmDialog,
  },

  props: {
    interviewId: { type: String },
    interviews: { type: Array },
    selectedCandidate: { type: Object },
    selectedRecruter: { type: Object },
    isCompleted: { type: Boolean },
    isRunning: { type: Boolean },
    isInterviewer: { type: Boolean },
  },

  data: () => ({
    apiUrl: API_URL,
    dialogConfirm: {
      dialog: false,
      question: "",
      id: null,
    },
    changingRecruter: true,
  }),

  created() {},

  computed: {
    recruters() {
      const CURRENT_STAGE = this.selectedCandidate.currentStage;
      const findedStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === CURRENT_STAGE
      );

      return findedStage.recruters;
    },

    observers() {
      const CURRENT_STAGE = this.selectedCandidate.currentStage;
      const findedStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === CURRENT_STAGE
      );

      return findedStage.observers;
    },

    userId() {
      return this.$root.$data.userId;
    },
  },

  methods: {
    openAddRecruterDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("dialogAddRecruter", {
        interviewId: this.interviewId,
        existingRecruters: this.recruters,
        existingObservers: this.observers,
        selectedCandidate: this.selectedCandidate,
        interviews: this.interviews,
      });
    },

    openAddObserverDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("dialogAddObserver", {
        interviewId: this.interviewId,
        existingRecruters: this.recruters,
        existingObservers: this.observers,
        selectedCandidate: this.selectedCandidate,
        interviews: this.interviews,
      });
    },

    setRecruterToDelete(recruterId) {
      this.changingRecruter = true;
      this.dialogConfirm.id = recruterId;
      this.dialogConfirm.question = this.$t(
        "interview_recruter_remove_question",
        { item: this.$t("interviewer") }
      );
      this.dialogConfirm.dialog = true;
    },

    setObserverToDelete(observerId) {
      this.changingRecruter = false;
      this.dialogConfirm.id = observerId;
      this.dialogConfirm.question = this.$t(
        "interview_recruter_remove_question",
        { item: this.$t("observer") }
      );
      this.dialogConfirm.dialog = true;
    },

    async removeRecruter(recruterId) {
      const candidateIds = this.getCandidateIdsFromInterviews();
      const currentStage = this.getCurrentStage();

      try {
        await this.$apollo.mutate({
          mutation: REMOVE_RECRUTER_FROM_INTERVIEW_MUTATION,
          variables: {
            interviewRemoveRecruterInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              stage: currentStage,
              recruterId,
            },
          },
        });

        this.dialogConfirm.dialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    async removeObserver(observerId) {
      const candidateIds = this.getCandidateIdsFromInterviews();
      const currentStage = this.getCurrentStage();

      try {
        await this.$apollo.mutate({
          mutation: REMOVE_OBSERVER_FROM_INTERVIEW_MUTATION,
          variables: {
            interviewRemoveObserverInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              stage: currentStage,
              observerId,
            },
          },
        });

        this.dialogConfirm.dialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },

    getCurrentStage() {
      const FIRST_ELEMENT = 0;
      const currentStage = this.interviews[FIRST_ELEMENT].currentStage;

      return currentStage;
    },

    selectRecruter(recruter) {
      this.$emit("select", recruter);
    },

    recruterName(recruterOrObserver) {
      return recruterOrObserver.user.id === this.userId
        ? this.$t("me")
        : this.recruterSplitName(recruterOrObserver.user.name);
    },

    yesDialog(id) {
      if (this.changingRecruter) {
        this.removeRecruter(id);
      } else {
        this.removeObserver(id);
      }
    },

    recruterSplitName(name) {
      return name.split(" ")[0];
    },
  },
};
</script>

<style scoped>
.recruter-name {
  width: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 32px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>