<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-5">
      <v-card-title class="justify-center text-uppercase"
        >{{ $t(textCard.title) }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="handleSubmit"
        >
          <v-row>
            <v-col cols="12" md="12" class="text-center">
              <!-- <pre>{{user.photo}}</pre> -->
              <v-list-item>
                <v-hover v-slot:default="{}">
                  <v-list-item-avatar class="mx-auto" color="grey" size="150">
                    <croppa
                      v-model="croppa"
                      ref="croppa"
                      class="croppa"
                      :width="150"
                      :height="150"
                      :placeholder="$t('form_candidate_upload')"
                      :placeholder-font-size="12"
                      :initial-image="
                        user.photo && textCard.value != 0
                          ? `${apiUrl}/images/user/${user.photo}`
                          : `/avatar.png`
                      "
                    >
                      <!-- <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-ease-in-ease-out orange darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-btn
                          text
                          outlined
                          color="white"
                          class
                          style="opacity: 1;"
                          fab
                          large
                          top
                          @click="$refs.croppa.chooseFile()"
                        >
                          <div class="text-center">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  fab
                                  class="mx-2"
                                  color="grey"
                                  v-on="on"
                                >
                                  <v-icon>mdi-camera-plus</v-icon>
                                </v-btn>
                              </template>
                              <span>Carregar foto</span>
                            </v-tooltip>
                          </div>
                        </v-btn>
                      </div>
                    </v-expand-transition> -->
                    </croppa>
                  </v-list-item-avatar>
                </v-hover>
              </v-list-item>
              <!-- <v-btn color="error" small @click="croppa.remove()">{{
                $t("remove")
              }}</v-btn> -->
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="primary"
                    style="margin-top: -15%; margin-left: 20%"
                    icon
                    small
                    @click="croppa.chooseFile()"
                    ><v-icon>mdi-camera-plus</v-icon></v-btn
                  >
                </template>
                <div v-html="$t('form_candidate_upload')"></div>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="12" class="pb-0">
              <v-text-field
                v-model="user.name"
                :readonly="show"
                :label="$t('form_user_name')"
                dense
                prepend-inner-icon="mdi-account"
                outlined
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model.trim="user.email"
                :readonly="show"
                label="Email"
                dense
                prepend-inner-icon="mdi-email"
                outlined
                required
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <vue-tel-input-vuetify
                v-model="user.telephone"
                :label="$t('form_user_phone')"
                persistent-hint
                outlined
                dense
                required
                :rules="requiredRules"
                @input="onInputPhone"
              >
              </vue-tel-input-vuetify>
              <!-- <v-text-field
                v-model.trim="user.telephone"
                :readonly="show"
                :label="$t('form_user_phone')"
                dense
                prepend-inner-icon="mdi-phone"
                outlined
                required
                type="number"
                :rules="requiredRules"
              ></v-text-field> -->
            </v-col>
            <v-col v-if="!show" cols="12" md="6" class="py-0">
              <v-text-field
                v-model.trim="user.password"
                :label="$t('form_user_pass')"
                type="password"
                dense
                prepend-inner-icon="mdi-lock"
                outlined
                required
                :rules="!user.id && requiredRules"
              ></v-text-field>
            </v-col>
            <v-col v-if="!show" cols="12" md="6" class="py-0">
              <v-text-field
                v-model.trim="repeatPassword"
                :label="$t('form_user_pass_check')"
                type="password"
                dense
                prepend-inner-icon="mdi-lock"
                outlined
                :rules="!user.id && repeatPasswordRules"
              ></v-text-field>
            </v-col>
            <v-col v-if="user.id != userId" cols="12" md="6" class="py-0">
              <v-text-field
                v-model="user.office"
                :readonly="show"
                :label="$t('form_user_Office')"
                dense
                prepend-inner-icon="mdi-account-box"
                outlined
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col v-if="user.id != userId" cols="12" md="6" class="py-0">
              <v-select
                v-model="user.role"
                :readonly="show"
                :label="$t('form_user_rule')"
                :items="roles"
                item-text="name"
                item-value="id"
                prepend-inner-icon="mdi-folder-key"
                dense
                outlined
                required
                :rules="requiredRules"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn color="primary" text class="mx-2" @click="$emit('close')">{{
              show ? $t("close") : $t("cancel")
            }}</v-btn>
            <v-btn
              v-if="!show"
              :disabled="isLoading"
              type="submit"
              color="primary"
              >{{ $t("save") }}</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="closeErrorDialog"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { formatError } from "@/utils";
import { CREATE_EMPLOYEE_MUTATION } from "./../graphql/Mutation";
import { UPDATE_USER_MUTATION } from "./../../auth/graphql/Mutation";
import { GET_ROLES_ENTITY_QUERY } from "./../graphql/Query";
import { mapActions, mapGetters } from "vuex";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
export default {
  name: "AddUserDialog",
  components: {
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
    VueTelInputVuetify,
  },
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
    user: Object,
    show: Boolean,
  },
  data: () => ({
    apiUrl: API_URL,
    croppa: {},
    errrValid: false,
    repeatPassword: "",
    roles: [],
    valid: true,
    phone: {
      number: "",
      valid: false,
      country: undefined,
    },
  }),
  apollo: {
    roles: {
      query: GET_ROLES_ENTITY_QUERY,
    },
  },

  created() {
    if (this.user.id) {
      this.user.telephone = "+" + this.user.telephone;
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    textCard() {
      return this.user.id
        ? { title: "edit_user_form", value: 1 }
        : { title: "cad_user_form", value: 0 };
    },
    userId() {
      return this.$root.$data.userId;
    },
    repeatPasswordRules() {
      return [
        (v) => v === this.user.password || this.$t("form_user_valid_check"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("form_user_valid"),
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
  },
  methods: {
    ...mapActions({
      setCurrentUserState: "auth/setUser",
      setUserState: "user/setUser",
      updateUserState: "user/updateUser",
    }),
    onInputPhone(formattedNumber, { number, valid, country }) {
      this.phone.number = number.international;
      this.phone.valid = valid;
      this.phone.country = country && country.name;
    },
    closeErrorDialog(value) {
      this.showError = value;
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        let newUser;
        try {
          this.user.telephone = this.phone.number
            .replace("+", "")
            .replace(/\s/g, "");
          if (this.croppa.hasImage()) {
            this.user.newPhoto = this.croppa.generateDataUrl("image/jpeg", 0.8);
          }
          if (this.user.id) {
            await this.$apollo
              .mutate({
                mutation: UPDATE_USER_MUTATION,
                variables: {
                  id: this.user.id,
                  userInput: Object.assign(
                    {},
                    { ...this.user, role: this.user.role.id, entity: undefined }
                  ),
                },
              })
              .then(({ data }) => {
                if (this.user.id == this.userId) {
                  this.setCurrentUserState(data.updateUser);
                } else {
                  this.updateUserState(data.updateUser);
                }
              });
            this.success = this.$t("form_user_edit_success");
          } else {
            const { data } = await this.$apollo.mutate({
              mutation: CREATE_EMPLOYEE_MUTATION,
              variables: { userInput: { ...this.user, role: this.user.role } },
            });
            this.setUserState(data.createEmployee);
            this.success = this.$t("form_user_cad_success");
            newUser = data.createEmployee;
          }
          this.$emit("close", newUser);

          this.showSuccess = true;
        } catch (error) {
          console.log(error);
          this.error = this.$t(formatError(error.message));
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.errrValid = true;
      }
    },
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.show-btns:not(.on-hover) {
  color: #f7a400 !important;
}
.croppa {
  background: #141838;
  /* margin-left: -2%; */
}
</style>
