<template>
  <div class="card">
    <div class="status">
      <div class="skeleton status-chip"></div>
    </div>

    <div class="customer">
      <div class="skeleton skeleton-text"></div>
      <div class="skeleton skeleton-text"></div>
    </div>

    <div class="vacancy">
      <div class="skeleton skeleton-text"></div>
      <div class="skeleton skeleton-text"></div>
    </div>

    <div class="splitter"></div>

    <div class="date">
      <div class="skeleton date-chip"></div>
      <div class="skeleton date-chip"></div>
    </div>

    <div class="interviewer">
      <div class="skeleton skeleton-text"></div>
      <div class="interviewer-group">
        <div v-for="i in 5" :key="i" class="interviewer-img">
          <div class="skeleton"></div>
        </div>
      </div>
    </div>

    <div class="interviewer">
      <div class="skeleton skeleton-text"></div>
      <div class="interviewer-group">
        <div v-for="i in 3" :key="i" class="interviewer-img">
          <div class="skeleton"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InterviewCardLoader",
};
</script>

<style scoped>
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  /* width: 100%; */
  width: 15%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 50%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.card {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 16px 12px;
  border-radius: 4px;
}

.status {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.status-chip {
  width: 70px;
  height: 20px;
}

.customer {
  margin-bottom: 40px;
}

.vacancy {
  margin-bottom: 20px;
}

.splitter {
  border: 0.1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.date {
  display: flex;
  margin-bottom: 20px;
}

.date-chip {
  width: 80px;
  height: 20px;
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.interviewer {
  margin-bottom: 20px;
}

.interviewer-group {
  display: flex;
  align-items: center;
  justify-content: left;
}

.interviewer-img {
  position: relative;
  transition: 0.2s;
}

.interviewer-img {
  position: relative;
  transition: 0.2s;
}

.interviewer-img:not(:first-child) {
  margin-left: -1rem;
}

.interviewer-img div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>