var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flx flex-column"},[_c('div',[_vm._v(_vm._s(_vm.$t("comments")))]),_c('div',{staticClass:"scroll-comments pa-5"},[(!_vm.isOwner)?_c('v-row',{staticClass:"d-flex flex-column mb-10"},_vm._l((_vm.selectedRecruter.comments),function(comment,index){return _c('div',{key:comment.id,staticClass:"mb-2",staticStyle:{"max-width":"90%"}},[_c('div',{staticClass:"card-chat",staticStyle:{"min-width":"150px"}},[(index === 0)?_c('div',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.recruterName(_vm.selectedRecruter))+" ")]):_vm._e(),_c('div',{staticClass:"chat-msg-box"},[_c('div',{staticClass:"chat-msg pl-2",domProps:{"innerHTML":_vm._s(comment.text)}}),_c('div',{staticClass:"chat-msg-time text-end text--secondary"},[_vm._v(" "+_vm._s(_vm.localDate(comment.date))+" "),(_vm.isOwner)?_c('v-btn',{staticClass:"pa-0 ma-0",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.setCommentToDelete(comment.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)])])])}),0):_c('v-row',{staticClass:"d-flex flex-column mb-10"},_vm._l((_vm.allComments),function(comment,index){return _c('div',{key:comment.id,staticClass:"mb-2",class:comment.recruterId !== _vm.userId
            ? 'whirelab-comment-home'
            : 'whirelab-comment-guest'},[_c('div',{staticClass:"card-chat",staticStyle:{"min-width":"150px"}},[(
              (comment.name !== _vm.beforeComment(index) || index === 0) &&
              comment.recruterId !== _vm.userId
            )?_c('div',{class:comment.recruterId === _vm.userId
                ? 'text-right primary--text'
                : 'teal--text'},[_vm._v(" "+_vm._s(comment.name)+" ")]):_vm._e(),_c('div',{staticClass:"chat-msg-box"},[_c('div',{staticClass:"chat-msg pl-2",domProps:{"innerHTML":_vm._s(comment.text)}}),_c('div',{staticClass:"chat-msg-time text-end text--secondary"},[_vm._v(" "+_vm._s(_vm.localDate(comment.date))+" "),(_vm.isOwner)?_c('v-btn',{staticClass:"pa-0 ma-0",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.setCommentToDelete(comment.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)])])])}),0)],1),_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('tiptap-vuetify',{staticClass:"mb-2",staticStyle:{"max-width":"100%"},attrs:{"extensions":_vm.extensions,"placeholder":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12"}},[_c('div',{staticStyle:{"max-width":"100%"}},[_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.addComment()}}},[_vm._v(_vm._s(_vm.$t("share")))])],1)])],1)],1),_c('confirm-dialog',{attrs:{"dialogConfirm":_vm.dialogConfirm},on:{"yes":_vm.deleteComment,"no":function($event){_vm.dialogConfirm.dialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }