<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="date"
        :label="$t('interview_date')"
        prepend-inner-icon="event"
        readonly
        v-on="on"
        dense
        outlined
        hide-details
        :rules="requiredRules"
        @input="$emit('input', date)"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      :min="dateFormat()"
      @input="$emit('input', date)"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">{{
        $t("cancel")
      }}</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { format } from "date-fns";
export default {
  name: "DatePicker",

  props: {
    currentDate: { type: String },
  },

  data: () => ({
    date: null,
    menu: false,
  }),

  watch: {
    currentDate(val) {
      if (!val) {
        this.date = null;
      }
    },
  },

  computed: {
    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
  },

  methods: {
    dateFormat() {
      return format(new Date(), "yyyy-MM-dd");
    },
  },
};
</script>