<template>
  <div class="d-flx flex-column">
    <div>{{ $t("comments") }}</div>
    <div class="scroll-comments pa-5">
      <v-row v-if="!isOwner" class="d-flex flex-column mb-10">
        <div
          v-for="(comment, index) in selectedRecruter.comments"
          :key="comment.id"
          class="mb-2"
          style="max-width: 90%"
        >
          <div class="card-chat" style="min-width: 150px">
            <div v-if="index === 0" class="primary--text">
              {{ recruterName(selectedRecruter) }}
            </div>
            <div class="chat-msg-box">
              <div class="chat-msg pl-2" v-html="comment.text"></div>
              <div class="chat-msg-time text-end text--secondary">
                {{ localDate(comment.date) }}
                <v-btn
                  v-if="isOwner"
                  icon
                  x-small
                  class="pa-0 ma-0"
                  @click="setCommentToDelete(comment.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-row>
      <v-row v-else class="d-flex flex-column mb-10">
        <div
          v-for="(comment, index) in allComments"
          :key="comment.id"
          class="mb-2"
          :class="
            comment.recruterId !== userId
              ? 'whirelab-comment-home'
              : 'whirelab-comment-guest'
          "
        >
          <div class="card-chat" style="min-width: 150px">
            <div
              v-if="
                (comment.name !== beforeComment(index) || index === 0) &&
                comment.recruterId !== userId
              "
              :class="
                comment.recruterId === userId
                  ? 'text-right primary--text'
                  : 'teal--text'
              "
            >
              {{ comment.name }}
            </div>
            <div class="chat-msg-box">
              <div class="chat-msg pl-2" v-html="comment.text"></div>
              <div class="chat-msg-time text-end text--secondary">
                {{ localDate(comment.date) }}
                <v-btn
                  v-if="isOwner"
                  icon
                  x-small
                  class="pa-0 ma-0"
                  @click="setCommentToDelete(comment.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-row>
    </div>

    <div>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <!-- <div style="max-width: 90%"> -->
          <tiptap-vuetify
            v-model="comment"
            :extensions="extensions"
            placeholder
            class="mb-2"
            style="max-width: 100%"
          />
          <!-- </div> -->
        </v-col>
        <v-col cols="12" md="12" class="text-right">
          <div style="max-width: 100%">
            <v-btn
              small
              color="primary"
              :loading="isLoading"
              @click="addComment()"
              >{{ $t("share") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>

    <confirm-dialog
      :dialogConfirm="dialogConfirm"
      @yes="deleteComment"
      @no="dialogConfirm.dialog = false"
    />
  </div>
</template>

<script>
import format from "date-fns/format";
import { pt, enUS } from "date-fns/locale";
import {
  ADD_COMMENT_FROM_INTERVIEWER_MUTATION,
  REMOVE_COMMENT_INTERVIEW_MUTATION,
} from "../../graphql/Mutation.gql";
import ConfirmDialog from "../ConfirmDialog.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  name: "Comments",

  components: {
    TiptapVuetify,
    ConfirmDialog,
  },

  props: {
    interviewId: { type: String },
    interviews: { type: Array },
    selectedCandidate: { type: Object },
    selectedRecruter: { type: Object },
    selectedStage: { type: String },
    isCompleted: { type: Boolean },
    isRunning: { type: Boolean },
  },

  data: () => {
    return {
      comment: "",
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      dialogConfirm: {
        dialog: false,
        question: "",
        id: null,
      },
      isLoading: false,
    };
  },

  computed: {
    userId() {
      return this.$root.$data.userId;
    },

    isOwner() {
      return this.selectedRecruter.user.id === this.userId;
    },

    allComments() {
      let messages = [];
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedStage
      );

      currentStage.recruters.forEach((eachRecruter) =>
        eachRecruter.comments.forEach((eachComment) => {
          messages.push({
            ...eachComment,
            name: eachRecruter.user.name,
            recruterId: eachRecruter.user.id,
          });
        })
      );

      currentStage.observers.forEach((eachRecruter) =>
        eachRecruter.comments.forEach((eachComment) => {
          messages.push({
            ...eachComment,
            name: eachRecruter.user.name,
            recruterId: eachRecruter.user.id,
          });
        })
      );

      return messages.sort((a, b) =>
        a.date > b.date ? 1 : b.date > a.date ? -1 : 0
      );
    },
  },

  methods: {
    async addComment() {
      this.isLoading = true;
      const candidateIdOrEmail = this.getSelectedCandidateIdOrEmail();
      const stage = this.selectedCandidate.currentStage;

      try {
        await this.$apollo.mutate({
          mutation: ADD_COMMENT_FROM_INTERVIEWER_MUTATION,
          variables: {
            interviewAddCommentInput: {
              interviewId: this.interviewId,
              candidateIdOrEmail,
              stage,
              recruterId: this.userId,
              comment: this.comment,
              isObserver: this.selectedRecruter.isObserver,
            },
          },
        });
        this.comment = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    setCommentToDelete(commentId) {
      this.dialogConfirm.id = commentId;
      this.dialogConfirm.question = this.$t(
        "interview_comment_remove_question"
      );
      this.dialogConfirm.dialog = true;
    },

    async deleteComment(id) {
      try {
        const candidateIdOrEmail = this.getSelectedCandidateIdOrEmail();
        const stage = this.selectedCandidate.currentStage;

        await this.$apollo.mutate({
          mutation: REMOVE_COMMENT_INTERVIEW_MUTATION,
          variables: {
            interviewRemoveCommentInput: {
              interviewId: this.interviewId,
              candidateIdOrEmail,
              stage,
              recruterId: this.userId,
              commentId: id,
              isObserver: this.selectedRecruter.isObserver,
            },
          },
        });
        this.dialogConfirm.dialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    getSelectedCandidateIdOrEmail() {
      return this.selectedCandidate.interviewee
        ? this.selectedCandidate.interviewee.id
        : this.selectedCandidate.email;
    },

    recruterName(recruterOrObserver) {
      return this.isOwner ? this.$t("me") : recruterOrObserver.user.name;
    },

    beforeComment(index) {
      if (index > 0) {
        return this.allComments[index - 1].name;
      }

      return 0;
    },

    localDate(date) {
      const currentLang = this.$i18n.locale;

      let formattedDate;
      switch (currentLang) {
        case "en":
          formattedDate = format(new Date(date), "yyyy/MM/dd hh:mm a", {
            locale: enUS,
          });
          break;

        default:
          formattedDate = format(new Date(date), "dd/MM/yyyy HH:mm", {
            locale: pt,
          });
          break;
      }

      return formattedDate;
    },
  },
};
</script>

<style>
.scroll-comments {
  max-height: 350px;
  overflow: auto;
}

.card-chat {
  background: rgb(238, 238, 238);
  border-radius: 10px;
  padding: 5px 10px;
  display: inline-block;
}

.chat-msg-time {
  font-size: 12px;
  /* margin-right: 10px; */
  margin-top: 0;
}

.chat-msg p {
  margin-bottom: 0px !important;
}

.whirelab-comment-home {
  display: flex;
  justify-content: flex-start;
  max-width: 90%;
}
.whirelab-comment-guest {
  display: flex;
  justify-content: flex-end;
  margin-left: 5%;
  margin-right: 3%;
}
</style>