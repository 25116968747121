<template>
  <div>
    <v-card v-if="candidate" :flat="flat">
      <v-row>
        <!-- <pre>{{candidate.id}}</pre> -->
        <v-col cols="12" md="4">
          <v-list-item>
            <v-list-item-content>
              <v-card :elevation="0" max-height="450">
                <v-card flat fluid>
                  <v-img
                    lazy-src="https://picsum.photos/id/11/10/6"
                    :src="
                      candidate.photo
                        ? `${apiUrl}/images/candidate/${candidate.photo}`
                        : `/avatar.png`
                    "
                    contain
                  >
                  </v-img>
                  <v-card-text>
                    <div class="text-center">
                      <v-list-item>
                        <v-list-item-content>
                          <strong class="body font-weight-bold text-uppercase">
                            {{ candidate.name + " " + candidate.lastName }}
                          </strong>
                          <div
                            class="
                            text-subtitle-2
                            font-weight-medium
                            text--disabled
                          "
                          >
                            {{ candidate.profession }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-row justify="center" v-if="candidate.media">
                            <v-btn icon @click="dialog = true">
                              <v-icon small color="primary">mdi-email</v-icon>
                            </v-btn>
                            <v-btn
                              :href="`//${candidate.media.facebook}`"
                              target="_blank"
                              icon
                            >
                              <v-icon small color="primary"
                                >mdi-facebook</v-icon
                              >
                            </v-btn>
                            <v-btn
                              :href="`//${candidate.media.linkedin}`"
                              target="_blank"
                              icon
                            >
                              <v-icon small color="primary"
                                >mdi-linkedin</v-icon
                              >
                            </v-btn>
                            <v-btn
                              :href="`//${candidate.media.twitter}`"
                              target="_blank"
                              icon
                            >
                              <v-icon small color="primary">mdi-twitter</v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-list-item-content>
          </v-list-item>

          <div class="px-3" style="margin-top: 0%">
            <p class="pt-3 body black--text font-weight-medium">
              {{ $t("form_candidate_tools") }}
            </p>
            <v-chip-group column>
              <v-chip
                v-for="(skill, index) in candidate.otherSkills"
                :key="index"
                label
                small
                >{{ skill }}</v-chip
              >
            </v-chip-group>
          </div>
          <div class="px-3">
            <p class="pt-5 body black--text font-weight-medium">
              {{ $t("form_candidate_inter") }}
            </p>
            <v-chip-group column multiple>
              <v-chip
                v-for="(area, index) in candidate.areasInterest"
                :key="index"
                label
                small
                >{{ $t(area) }}</v-chip
              >
            </v-chip-group>
          </div>
        </v-col>

        <v-col cols="12" md="8">
          <v-row class="d-none d-sm-flex py-4">
            <v-btn
              v-for="tab in tabs"
              :key="tab"
              dense
              text
              small
              class="caption text-none"
              :class="{ 'text--disabled': tab.key != selectedTab }"
              :color="tab.key === selectedTab ? 'primary' : ''"
              @click="selectedTab = tab.key"
            >
              {{ tab.value }}
              <v-icon small right>mdi-play</v-icon>
            </v-btn>
          </v-row>

          <div v-if="selectedTab === 'profile'">
            <div class="mt-5 pr-5">
              <div class="body mb-5 text-uppercase">{{ $t("about") }}</div>
              <p class="body-2 font-weight-regular grey--text">
                {{ candidate.presentation }}
              </p>
            </div>
            <v-divider class="my-6"></v-divider>
            <div
              class="d-flex flex-row justify-space-between py-3 pr-5 ajustaEspaco2"
            >
              <div class="text--secondary body-2">
                {{ $t("form_candidate_Availability") }}
              </div>
              <div class="primary--text body-2">
                {{ $t(candidate.availability) }}
              </div>
            </div>
            <v-divider></v-divider>
            <v-row class="py-4">
              <!-- <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content>
                <v-list-item-title class="body">1ª Lingua</v-list-item-title>
                <v-list-item-subtitle
                  class="body-2 font-weight-regular"
                  v-if="
                    candidate.languages &&
                      candidate.languages[0] &&
                      candidate.languages[0].name
                  "
                >
                  {{ candidate.languages[0].name }} -
                  {{ candidate.languages[0].level }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="body-2 font-weight-regular" v-else>
                  --- - ---
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col> -->
              <!-- <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content class="py-0">
                <v-list-item-title class="body">2ª Lingua</v-list-item-title>
                <v-list-item-subtitle
                  class="body-2 font-weight-regular"
                  v-if="candidate.languages && candidate.languages[1]"
                >
                  {{ candidate.languages[1].name }} -
                  {{ candidate.languages[1].level }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="body-2 font-weight-regular" v-else
                  >--- - ---</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col> -->

              <v-col cols="12" md="6" class="py-0">
                <v-list-item two-line style="padding-left: 0">
                  <v-list-item-content>
                    <v-list-item-title class="body">{{
                      $t("professionalExperience")
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      class="body-2 font-weight-regular py-2"
                      >{{ days(candidate.professionalExperience) }}
                      {{ $t("year")
                      }}{{
                        days(candidate.professionalExperience) > 1 ? "s" : ""
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-list-item two-line style="padding-left: 0">
                  <v-list-item-content>
                    <v-list-item-title class="body">{{
                      this.$t("Interviesalary")
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      class="body-2 font-weight-regular py-2"
                    >
                      MT{{ candidate.basicSalary }} - MT{{
                        candidate.idealSalary
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="d-flex flex-row px-3 py-8">
              <div
                class="d-flex flex-row justify-space-between py-4 pr-5 ajustaEspaco"
              >
                <v-chip color="primary" label @click="dialogInterview = true">{{
                  $t("schedule_interview")
                }}</v-chip>
                <v-chip
                  label
                  outlined
                  color="primary"
                  class="mx-2"
                  @click="dialog_cv = true"
                  >{{ $t("view") }} CV</v-chip
                >
                <v-chip outlined label color="primary" @click="showDocuments()">
                  {{ $t("doc_label") }}
                </v-chip>
                <v-chip
                  outlined
                  label
                  color="primary"
                  @click="showCertificate()"
                  style="border: none;"
                >
                  {{ $t("view_cert") }}
                </v-chip>
              </div>
              <!-- <div
                class="d-flex flex-row justify-space-between py-4 pr-5 ajustaEspaco mt-0"
              >
                <pre>{{ getDocuments }}</pre>
              </div> -->
            </v-row>

            <v-row class="d-flex flex-row px-3 pb-4" v-if="v_doc">
              <div
                class="d-flex flex-row px-2"
                v-for="(doc, index) in getDocuments"
                :key="index"
              >
                <div>
                  <!-- {{ doc }} -->
                  <a
                    :href="`${apiUrl}/files/vacancy/documents/${doc}`"
                    target="_blank"
                    >{{ doc.length > 20 ? doc.substring(0, 20) : doc }}</a
                  >
                </div>
              </div>
            </v-row>
            <v-row class="d-flex flex-row px-3 pb-4" v-if="certificate">
              <div
                class="d-flex flex-row px-2"
                v-for="(cert, index) in candidate.certificates"
                :key="index"
              >
                <div>
                  <a
                    :href="`${apiUrl}/files/certifications/${cert}`"
                    target="_blank"
                    >{{
                      cert.length > 20 ? cert.substring(0, 20) + ".pdf" : cert
                    }}</a
                  >
                </div>
              </div>
            </v-row>
            <v-divider></v-divider>
            <v-row class="py-4">
              <v-col cols="12" md="6" class="py-0">
                <v-list-item two-line style="padding-left: 0">
                  <v-list-item-content>
                    <v-list-item-title class="body black--text"
                      >E-mail</v-list-item-title
                    >
                    <v-list-item-subtitle class="body-2 font-weight-regular">{{
                      candidate.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-list-item two-line style="padding-left: 0">
                  <v-list-item-content>
                    <v-list-item-title class="body black--text">{{
                      $t("phone")
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="body-2 font-weight-regular">{{
                      candidate.telephone
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-list-item two-line style="padding-left: 0">
                  <v-list-item-content>
                    <v-list-item-title class="body black--text">{{
                      $t("form_candidate_birth")
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="body-2 font-weight-regular">{{
                      dateFormat(candidate.dateBirth)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-list-item two-line style="padding-left: 0">
                  <v-list-item-content>
                    <v-list-item-title class="body black--text">{{
                      $t("address")
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="body-2 font-weight-regular">{{
                      candidate.address
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <!-- <pre>{{currentUser.entity.id}}</pre> -->
              <v-col cols="12" md="12" class="pb-0 mb-0 my-3 black--text">{{
                $t("form_candidate_ref")
              }}</v-col>
              <v-col
                cols="12"
                md="6"
                class="py-0"
                v-for="reference in candidate.references"
                :key="reference.fullName"
              >
                <v-list-item two-line style="padding-left: 0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="body black--text">{{
                      reference.fullName
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="body-2 font-weight-regular my-1"
                      >{{ reference.office }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="body-2 font-weight-regular my-3"
                      ><v-icon small>mdi-phone</v-icon>
                      {{ reference.telephone }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <!-- {{candidate.id}} -->
              </v-col>
            </v-row>
          </div>
          <!-- >> {{ vacancyId }} -->
          <interview
            v-if="selectedTab === 'interviews'"
            :candidate_id="candidate.id"
            :vacancyId="vacancyId"
          />
          <div v-if="selectedTab === 'Tests_done'">
            <TestsDone
              :vacancies="filterVacancies"
              :candidateId="candidate.id"
            />
          </div>
        </v-col>
      </v-row>
      <schedule-interview-dialog
        :dialog="dialogInterview"
        :selectedCandidates="[candidate]"
        :vacancyId="vacancyId"
        @close="dialogInterview = false"
      />
      <ShareCVDialog />
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1200"
        :filename="candidate.id"
        :pdf-quality="4"
        :manual-pagination="false"
        pdf-format="a4"
        :html-to-pdf-options="htmlToPdfOptions"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
      </vue-html2pdf>
      <send-email-to-candidate
        :dialog="dialog"
        :candidate="candidate"
        @close="closeAddDialog()"
        @done="sendEmailDone()"
      ></send-email-to-candidate>
      <success-dialog
        :dialog="showSuccess"
        :message="success"
        @close="showSuccess = false"
      />
    </v-card>
    <dialogCV
      v-if="dialog_cv"
      :dialog="dialog_cv"
      @close="dialog_cv = !dialog_cv"
      :candidate="candidate"
    />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import { mapGetters } from "vuex";
import ScheduleInterviewDialog from "./ScheduleInterviewDialog.vue";
import moment from "moment";
import yearsMixins from "@/mixins/years";
import { ADD_VIEW } from "../graphql/Mutation.resolver";
// import { CLIENT } from "../../../../../api";
import ShareCVDialog from "../components/ShareCVDialog.vue";
import SendEmailToCandidate from "./SendEmailToCandidate.vue";
import VueHtml2pdf from "vue-html2pdf";
import SuccessDialog from "@/modules/entity/components/SuccessDialog.vue";
import interview from "./tabs/interview/interviews.vue";
import TestsDone from "./tabs/test/TestsDone.vue";
// import cvhtml from "../components/cv_html.vue";
import dialogCV from "./dialogCV.vue";
export default {
  name: "ProfileDetails",
  components: {
    ScheduleInterviewDialog,
    ShareCVDialog,
    VueHtml2pdf,
    SendEmailToCandidate,
    SuccessDialog,
    dialogCV,
    interview,
    TestsDone,
  },
  props: {
    candidate: Object,
    vacancy: Object,
    candidates: Array,
    vacancies: Array,
    filter: Object,
    vacancyId: String,
    flat: Boolean,
  },
  mixins: [yearsMixins],
  data: () => ({
    apiUrl: API_URL,
    certificate: false,
    v_doc: false,
    dialogInterview: false,
    dialog: false,
    showSuccess: false,
    dialog_cv: false,
    success: "",
    // tabs: [
    //   "Perfil",
    //   "Actividades",
    //   "Testes Feitos",
    //   "Entrevistas",
    //   "Contratado",
    // ],
    selectedTab: "profile",
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    filterVacancies() {
      return this.vacancies.filter((v) => {
        if (v.candidacies.find((cdt) => cdt.candidate === this.candidate.id)) {
          if (
            v.candidacies.find((cdt) => cdt.candidate === this.candidate.id)
              .tests.length
          ) {
            return v.candidacies.filter(
              (c) => c.candidate === this.candidate.id && v.candidacies.length
            );
          }
        }
      });
    },
    getDocuments() {
      if (this.vacancy.candidacies.length) {
        return this.vacancy.candidacies.find(
          (candidancy) =>
            candidancy.candidate &&
            candidancy.candidate.id === this.candidate.id
        ).documents;
      } else {
        return [];
      }
    },
    filterDocumentsVacancy() {
      return this.vacancies.filter((v) => {
        if (v.candidacies.find((cdt) => cdt.candidate === this.candidate.id)) {
          if (
            v.candidacies.find((cdt) => cdt.candidate === this.candidate.id)
              .documents.length
          ) {
            return v.candidacies.filter(
              (c) => c.candidate === this.candidate.id && v.candidacies.length
            );
          }
        }
      });
    },
    tabs() {
      return [
        { key: "profile", value: this.$t("form_candidate_profile") },
        { key: "interviews", value: this.$t("interviews") },
        { key: "Tests_done", value: this.$t("Tests_done") },
        { key: "activities", value: this.$t("activities") },
        { key: "Hired", value: this.$t("Hired") },
      ];
    },
  },
  methods: {
    dateFormat(date) {
      return moment(date)
        .lang("pt")
        .format("LL");
    },
    openShareDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareCandidateCV", this.candidate);
    },
    goCV() {
      this.$router.push({
        path: `/dashboard/entity/cv/${this.candidate.id}`,
        query: {
          filter: this.filter,
          vacancyId: this.vacancyId,
          from: this.$route.query.from,
        },
      });
      // this.openShareDialog();
      // window.open(CLIENT + `localhost:8080_pdf_${this.candidate.id}`, "_blank");
    },
    showCertificate() {
      this.certificate = !this.certificate;
    },
    showDocuments() {
      this.v_doc = !this.v_doc;
    },
    async addView() {
      await this.$apollo
        .mutate({
          mutation: ADD_VIEW,
          variables: {
            candidateId: this.currentUser.candidate.id,
            entity: this.currentUser.entity.id,
          },
        })
        .then((res) => {
          if (res) {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    closeAddDialog() {
      this.show = false;
      this.dialog = false;
    },
    sendEmailDone() {
      this.closeAddDialog();
      this.success = this.$t("email_sent_successfully");
      this.showSuccess = true;
    },
  },
  mounted() {
    this.addView();
  },
};
</script>

<style>
.subtile {
  font-size: 1.2rem !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.small-perfil:hover {
  border: 1px solid #f7a400;
}
.show-btns:not(.on-hover) {
  color: #f7a400 !important;
}
.ajustaEspaco {
  margin-top: -3%;
  margin-bottom: -2.5%;
}
.ajustaEspaco2 {
  margin-top: -3%;
  margin-bottom: -0.5%;
}
@media screen and (min-width: 300px) and (max-width: 1366px) {
  .ajustaEspaco {
    margin-top: -4%;
    margin-bottom: -3.5%;
  }
  .ajustaEspaco2 {
    margin-top: -6%;
    margin-bottom: -1.7%;
  }
}
</style>
