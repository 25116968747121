<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-5">
      <v-card-text>
        <div align="center" class="pt-2">
          <p>{{ $t("add_observer") }}</p>
        </div>

        <v-row>
          <v-col cols="12" md="12">
            <v-combobox
              v-model="observers"
              :items="filteredObservers"
              :label="$t('select_observers')"
              chips
              item-text="name"
              item-value="id"
              multiple
              dense
              outlined
              hide-details
              @change="changedUser"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  small
                  @click="data.select"
                  @click:close="removeRecruter(data.item)"
                >
                  <v-avatar left size="24">
                    <v-img
                      :src="
                        data.item.photo
                          ? `${apiUrl}/images/user/${data.item.photo}`
                          : `/avatar.png`
                      "
                    ></v-img>
                  </v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item === 'object'">
                  <v-list-item-avatar size="24">
                    <img
                      :src="
                        data.item.photo
                          ? `${apiUrl}/images/user/${data.item.photo}`
                          : `/avatar.png`
                      "
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.office"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar size="24" @click="openDialogAddUser()">
                    <v-icon>mdi-plus</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content
                    v-text="data.item"
                    @click="openDialogAddUser()"
                  ></v-list-item-content>
                </template>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-5">
        <v-row class="d-flex flex-row justify-end">
          <v-btn text @click="cancel()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" :loading="isLoading" @click="addRecruter()">
            {{ $t("Add") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>

    <add-user-dialog
      :dialog="dialogAddUser"
      :show="false"
      :user="{}"
      @close="closeAddDialog"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { ADD_OBSERVER_TO_INTERVIEW_MUTATION } from "../graphql/Mutation.gql";
import { GET_USERS_ENTITY_QUERY } from "../graphql/Query.gql";
import AddUserDialog from "../../setting/components/AddUserDialog.vue";
export default {
  name: "AddObserverDialog",

  components: {
    AddUserDialog,
  },

  data: () => ({
    apiUrl: API_URL,
    dialog: false,
    existingRecruters: [],
    existingObservers: [],
    observers: [],
    users: [],
    selectedCandidate: {},
    date: "",
    time: "",
    interviewId: null,
    interviews: [],
    isLoading: false,
    showAnotherFields: false,
    dialogAddUser: false,
  }),

  apollo: {
    users: {
      query: GET_USERS_ENTITY_QUERY,
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on(
      "dialogAddObserver",
      ({
        interviewId,
        existingRecruters,
        existingObservers,
        selectedCandidate,
        interviews,
      }) => {
        const FIRST_ELEMENT = 0;
        this.existingRecruters = existingRecruters;
        this.existingObservers = existingObservers || [];
        this.selectedCandidate = selectedCandidate;
        this.date = selectedCandidate.stages[FIRST_ELEMENT].date;
        this.time = selectedCandidate.stages[FIRST_ELEMENT].time;
        this.interviewId = interviewId;
        this.interviews = interviews;
        this.dialog = true;
      }
    );
  },

  computed: {
    filteredObservers() {
      const existingRecruterIds = this.existingRecruters.map(
        (mapRecruter) => mapRecruter.user.id
      );

      let resultItems = this.users;

      resultItems = resultItems.filter(
        (findRecruter) => !existingRecruterIds.includes(findRecruter.id)
      );

      if (this.existingRecruters.length > 0) {
        const existingObserverIds = this.existingObservers.map(
          (mapObserver) => mapObserver.user.id
        );

        resultItems = resultItems.filter(
          (findObserver) => !existingObserverIds.includes(findObserver.id)
        );
      }

      resultItems.push({ divider: true });
      resultItems.push(this.$t("add_new"));

      return resultItems;
    },
  },

  methods: {
    async addRecruter() {
      this.isLoading = true;
      const candidateIds = this.getCandidateIdsFromInterviews();
      const currentStage = this.getCurrentStage();

      try {
        await this.$apollo.mutate({
          mutation: ADD_OBSERVER_TO_INTERVIEW_MUTATION,
          variables: {
            interviewAddObserverInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              stage: currentStage,
              observers: this.observers.map((mapObserver) => mapObserver.id),
            },
          },
        });

        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },

    getCurrentStage() {
      const FIRST_ELEMENT = 0;
      const currentStage = this.interviews[FIRST_ELEMENT].currentStage;

      return currentStage;
    },

    cancel() {
      this.dialog = false;
    },

    openDialogAddUser() {
      this.dialogAddUser = true;
    },

    closeAddDialog(user) {
      if (user) {
        this.users.push(user);
        this.observers.push(user);
      }
      this.dialogAddUser = false;
    },

    changedUser(value) {
      const indexRemove = value.length - 1;
      if (typeof value[indexRemove] !== "object")
        this.observers.splice(indexRemove, 1);
    },

    removeRecruter(recruter) {
      const indexRemove = this.observers.findIndex(
        (findRecruter) => findRecruter.id === recruter.id
      );
      this.observers.splice(indexRemove, 1);
    },
  },
};
</script>