<template>
    <v-container class="fill-heigt" fluid>
     
      <v-row v-if="loadingData && viewGrid">
        <template v-for="i in 10">
          <v-col cols="12" md="3" :key="i">
            <interview-card-loader />
          </v-col>
        </template>
      </v-row>

      <v-row class="px-0">
        <v-col cols="12" class="px-0">
          <!-- {{ vacancyId }} -->
          <interview-table :interviews="filteredInterview" :candidate_id="candidate_id" :vacancyId="vacancyId"/>
        </v-col>
      </v-row>
  
      <schedule-interview />
    </v-container>
  </template>
  
  <script>
  import { GET_INTERVIEWS_QUERY } from "../../../../interview/graphql/Query.gql";
  import {
    INTERVIEW_SCHEDULE_SUBSCRIPTION,
    UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION,
  } from "../../../../interview/graphql/Subscraption.gql";
  import { VUE_VIEWS } from "@/constants/settings";
  import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
  // import InterviewCard from "../../../../interview/components/InterviewCard.vue";
  import accessLevelMixins from "@/mixins/access-level";
  import ScheduleInterview from "../../../../interview/components/ScheduleInterview";
  import InterviewCardLoader from "../../../../interview/components/skeleton-loader/InterviewCardLoader.vue";
//   import FilterInterview from "../../../interview/components/FilterInterview.vue";
  import InterviewTable from "./InterviewTable.vue";
  
  export default {
    name: "Interview",
  
    components: {
      // InterviewCard,
      ScheduleInterview,
      InterviewCardLoader,
    //   FilterInterview,
      InterviewTable,
    },
    props:["candidate_id","vacancyId"],
    mixins: [accessLevelMixins, replaceSpecialCharsMixins],
  
    data: () => ({
      text: "",
      interviews: [],
      loadingData: true,
      filterStatus: [1, 2],
      viewGrid: false,
    }),
  
    computed: {
      filteredInterview() {
        let list = this.interviews.filter(
          (findInterview) => findInterview.candidates[0]
        );
  
        list = list.map((mapList) => {
          mapList.sortBy = this.status(mapList);
          return mapList;
        });
  
        list.sort((a, b) => a.sortBy - b.sortBy);
  
        if (this.text) {
          const text = this.replaceSpecialChars(this.text);
          list = list.filter(
            (filterList) =>
              !!this.candidates(filterList).find((findCandidateName) => {
                return !!findCandidateName && findCandidateName.includes(text);
              }) ||
              !!this.recrutersAndObservers(filterList).find(
                (findCandidateName) => {
                  return !!findCandidateName && findCandidateName.includes(text);
                }
              ) ||
              this.replaceSpecialChars(filterList.vacancy.title).includes(text) ||
              (!!filterList.vacancy.client &&
                this.replaceSpecialChars(filterList.vacancy.client.name).includes(
                  text
                ))
          );
        }
  
        if (this.filterStatus.length > 0) {
          list = list.filter((filterList) =>
            this.filterStatus.includes(filterList.sortBy)
          );
        }

        list = list.filter(interview => {
           return interview.candidates.some(candidate => candidate.interviewee?candidate.interviewee.id === this.candidate_id:false)
        });
  
        return list;
      },
    },
  
    apollo: {
      interviews: {
        query: GET_INTERVIEWS_QUERY,
        fetchPolicy: "no-cache",
        result({ loading }) {
          if (!loading) {
            this.loadingData = false;
          }
        },
      },
      $subscribe: {
        interviewSchedule: {
          query: INTERVIEW_SCHEDULE_SUBSCRIPTION,
          result({ data }) {
            this.interviews.unshift(data.interviewSchedule);
          },
        },
        updateInterviewSchedule: {
          query: UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION,
          result({ data }) {
            const index = this.interviews.findIndex(
              (findInterview) =>
                findInterview.id == data.updateInterviewSchedule.id
            );
            this.interviews.splice(index, 1, data.updateInterviewSchedule);
          },
        },
      },
    },
  
    created() {
      this.viewGrid = JSON.parse(localStorage.getItem(VUE_VIEWS));
    },
  
    methods: {
      changeView(value) {
        this.viewGrid = value;
        localStorage.setItem(VUE_VIEWS, value);
      },
  
      openScheduleInterviewDialog() {
        // eslint-disable-next-line no-undef
        Fire.$emit("ScheduleUnAccountInterview");
      },
  
      changeFilter(filterStatus) {
        this.filterStatus = filterStatus;
      },
  
      candidates(interview) {
        return interview.candidates.map((mapCandidate) => {
          return mapCandidate.interviewee
            ? this.replaceSpecialChars(mapCandidate.interviewee.fullName)
            : mapCandidate.email;
        });
      },
  
      recrutersAndObservers(interview) {
        const TOTAL_CANDIDATES = interview.candidates.length;
        const CANDIDATES = interview.candidates;
        let candidateIndex = 0;
        let recruters = [];
        let observers = [];
  
        for (
          candidateIndex;
          candidateIndex < TOTAL_CANDIDATES;
          candidateIndex++
        ) {
          const STAGES = CANDIDATES[candidateIndex].stages;
          const findedStage = STAGES.find(
            (findStage) =>
              findStage.name === CANDIDATES[candidateIndex].currentStage
          );
          if (findedStage) recruters = [...recruters, ...findedStage.recruters];
  
          if (findedStage && findedStage.observers) {
            observers = [...observers, ...findedStage.observers];
          }
        }
  
        recruters = recruters.filter((findRecruter, index) => {
          const recruterIds = recruters.map((mapRecruter) => mapRecruter.user.id);
  
          return recruterIds.indexOf(findRecruter.user.id) === index;
        });
        observers = observers.filter((findObserver, index) => {
          const observerIds = observers.map((mapObserver) => mapObserver.user.id);
  
          return observerIds.indexOf(findObserver.user.id) === index;
        });
  
        recruters = recruters.map((mapRecruter) =>
          this.replaceSpecialChars(mapRecruter.user.name)
        );
        observers = observers.map((mapObserver) =>
          this.replaceSpecialChars(mapObserver.user.name)
        );
  
        recruters = [...recruters, ...observers];
  
        return recruters;
      },
  
      status(interview) {
        let states = [];
        const CANDIDATES = interview.candidates;
        CANDIDATES.forEach((mapCandidate) => {
          if (mapCandidate.status !== "pending") {
            states.push(mapCandidate.status);
          } else {
            mapCandidate.stages.forEach((mapStage) =>
              states.push(mapStage.state)
            );
          }
        });
  
        const STATUS = states;
        const TOTAL_STATUS = STATUS.length;
        let statusIndex = 0;
        let pending = 0,
          running = 0,
          canceled = 0,
          ongoing = 0,
          completed = 0;
  
        for (statusIndex; statusIndex < TOTAL_STATUS; statusIndex++) {
          switch (STATUS[statusIndex]) {
            case "pending":
              pending += 1;
              break;
  
            case "rejected":
            case "approved":
              completed += 1;
              break;
  
            case "canceled":
              canceled += 1;
              break;
  
            case "ongoing":
            case "running":
            case "pause":
            case "finished":
              ongoing += 1;
              break;
  
            default:
              running += 1;
              break;
          }
        }
  
        if (TOTAL_STATUS === pending) {
          return 1;
        } else if (TOTAL_STATUS === ongoing || ongoing >= 1 || running >= 1) {
          return 2;
        } else if (TOTAL_STATUS === canceled) {
          return 4;
        } else if (
          TOTAL_STATUS === completed ||
          completed + canceled === TOTAL_STATUS
        ) {
          return 3;
        }
  
        return 1;
      },
    },
  };
  </script>
  