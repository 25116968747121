<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-5">
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="reSchedule()"
        >
          <v-row justify="center" no-gutters>
            <v-alert
              v-model="alert"
              color="primary_text"
              dense
              type="info"
              class="text-center black--text caption mb-0"
              dismissible
              >{{ $t("rescheduleformtextalert") }}</v-alert
            >
          </v-row>
          <v-card-text>
            <div align="center" class="pt-2">
              <p>{{ $t("schedule_interview") }}</p>
            </div>
            <!-- {{ clients }}-->
            <!-- {{ vacancies }} -->
            <v-row>
              <v-col cols="12" md="12">
                <v-select
                  v-model="client"
                  :items="clients"
                  item-text="name"
                  item-value="id"
                  :label="$t('client')"
                  dense
                  outlined
                  hide-details
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-combobox
                  v-model="vacancy"
                  ref="vacancy"
                  :items="getVacancies"
                  item-text="title"
                  item-value="id"
                  outlined
                  :label="$t('select_vacancy')"
                  dense
                  hide-details
                  :filter="onFilter"
                  @input.native="vacancyTitle = $event.srcElement.value"
                ></v-combobox>
              </v-col>
              <v-col cols="12">
                <!-- {{ candidateEmails }} -->
                <candidate-custom-combobox
                  @change="setCandidates"
                  :rules="requiredRules"
                />
              </v-col>
              <v-col cols="12">
                <recruter-combobox
                  :existingRecruters="recruters"
                  :existingObservers="observers"
                  :isObserver="false"
                  @change="changeRecruters"
                />
              </v-col>
              <v-col cols="12">
                <recruter-combobox
                  :existingRecruters="recruters"
                  :existingObservers="observers"
                  :isObserver="true"
                  @change="changeObservers"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="subject"
                  :label="$t('subject')"
                  dense
                  outlined
                  hide-details
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <date-picker @input="setDate" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="time"
                  :label="$t('interview_time')"
                  v-mask="'##:##'"
                  placeholder="00:00"
                  prepend-inner-icon="access_time"
                  dense
                  outlined
                  hide-details
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="address"
                  :label="$t('phisic_adress')"
                  dense
                  prepend-inner-icon="place"
                  outlined
                  hide-details
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="telephone"
                  :label="$t('Institution_telephone')"
                  dense
                  prepend-inner-icon="mdi-phone"
                  outlined
                  hide-details
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="message"
                  rows="3"
                  :autocomplete="$t('enter_message')"
                  :label="$t('enter_message')"
                  :hint="$t('interviewCreateSuccesshint')"
                  persistent-hint
                  dense
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()" color="primary">
              {{ $t("finish") }}
            </v-btn>
            <v-btn
              class="mr-4 px-6"
              color="primary"
              type="submit"
              :loading="isLoading"
            >
              {{ $t("send") }}
            </v-btn>
            <v-btn
              absolute
              top=""
              right=""
              icon=""
              class="mt-5"
              @click="$emit('close')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import {
  SCHEDULE_INTERVIEW_UNACCOUNTED_MUTATION,
  SCHEDULE_INTERVIEW_UNACCOUNTED_WITH_VACANCY_MUTATION,
} from "../graphql/Mutation.gql";
import {
  GET_CLIENTS_ENTITY_QUERY,
  GET_VACANCIES_ENTITY_ALL_QUERY,
} from "../graphql/Query.gql";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import CandidateCustomCombobox from "./form/CandidateCustomCombobox.vue";
import RecruterCombobox from "./form/RecruterCombobox.vue";
import DatePicker from "./form/DatePicker.vue";
export default {
  name: "ScheduleUnaccountedInterview",

  components: {
    CandidateCustomCombobox,
    RecruterCombobox,
    DatePicker,
  },

  mixins: [replaceSpecialCharsMixins],

  data: () => ({
    apiUrl: API_URL,
    alert: true,
    dialog: false,
    candidateEmails: [],
    candidacies: [],
    interviews: [],
    recruters: [],
    observers: [],
    subject: "",
    date: null,
    time: null,
    address: "",
    telephone: "",
    message: "",
    stage: "",
    interviewId: null,
    isCurrentStage: true,
    client: null,
    clients: [],
    valid: true,
    isLoading: false,
    vacancy: "",
    vacancyId: "",
  }),

  apollo: {
    vacancies: {
      query: GET_VACANCIES_ENTITY_ALL_QUERY,
      fetchPolicy: "no-cache",
    },
    clients: {
      query: GET_CLIENTS_ENTITY_QUERY,
      fetchPolicy: "no-cache",
    },
  },

  watch: {
    dialog(value) {
      if (!value) {
        this.vacancyTitle = "";
        this.vacancyId = "";
        this.vacancy = null;
        this.$apollo.queries.vacancies.refetch();
      }
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("ScheduleUnAccountInterview", () => {
      this.dialog = true;
    });
  },

  computed: {
    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
    getVacancies() {
      if (this.client && this.vacancies) {
        return this.vacancies.filter(
          (vacancy) => vacancy.client && vacancy.client.id === this.client
        );
      }
      return this.vacancies;
    },
  },

  methods: {
    onFilter(item, queryText, itemText) {
      return this.replaceSpecialChars(itemText).includes(
        this.replaceSpecialChars(queryText)
      );
    },
    async reSchedule() {
      if (this.$refs.form.validate()) {
        if (
          !this.vacancyTitle ||
          (typeof this.vacancy === "object" &&
            this.replaceSpecialChars(this.vacancy.title).includes(
              this.replaceSpecialChars(this.vacancyTitle)
            ))
        ) {
          this.isLoading = true;
          const recruters = this.getRecruterIds();
          const observers = this.getObserverIds();
          try {
            await this.$apollo.mutate({
              mutation: SCHEDULE_INTERVIEW_UNACCOUNTED_WITH_VACANCY_MUTATION,
              variables: {
                interviewInput: {
                  address: this.address,
                  candidateEmails: this.candidateEmails,
                  clientId: this.client,
                  date: this.date,
                  message: this.message,
                  observers,
                  recruters,
                  subject: this.subject,
                  telephone: this.telephone,
                  time: this.time,
                  vacancy: this.vacancy.id,
                },
              },
            });
            let successMsg = this.$t("interviewSuccess");
            // eslint-disable-next-line no-undef
            Fire.$emit("SuccessDialog", successMsg);
            this.close();
            this.dialog = false;
          } catch (error) {
            console.log(error);
            let successMsg = this.$t("fill_candidate_email");
            // eslint-disable-next-line no-undef
            Fire.$emit("ErrorDialog", successMsg);
          } finally {
            this.isLoading = false;
          }
        } else {
          this.isLoading = true;
          const recruters = this.getRecruterIds();
          const observers = this.getObserverIds();
          try {
            await this.$apollo.mutate({
              mutation: SCHEDULE_INTERVIEW_UNACCOUNTED_MUTATION,
              variables: {
                interviewInput: {
                  address: this.address,
                  candidateEmails: this.candidateEmails,
                  clientId: this.client,
                  date: this.date,
                  message: this.message,
                  observers,
                  recruters,
                  subject: this.subject,
                  telephone: this.telephone,
                  time: this.time,
                  vacancyTitle: this.vacancy,
                },
              },
            });
            let successMsg = this.$t("interviewSuccess");
            // eslint-disable-next-line no-undef
            Fire.$emit("SuccessDialog", successMsg);
            this.close();
            this.dialog = false;
          } catch (error) {
            console.log(error);
            let successMsg = this.$t("fill_candidate_email");
            // eslint-disable-next-line no-undef
            Fire.$emit("ErrorDialog", successMsg);
          } finally {
            this.isLoading = false;
          }
        }
      }
    },

    getRecruterIds() {
      return this.recruters.map((mapRecruter) => mapRecruter.id);
    },

    getObserverIds() {
      return this.observers.map((mapObserver) => mapObserver.id);
    },

    setCandidates(candidateEmails) {
      this.candidateEmails = candidateEmails;
    },

    close() {
      this.dialog = false;
      this.candidateEmails = [];
      this.recruters = [];
      this.observers = [];
      this.subject = "";
      this.date = null;
      this.time = null;
      this.address = "";
      this.telephone = "";
      this.message = "";
      this.client = null;
      this.vacancy = "";
    },

    changeRecruters(recruters) {
      this.recruters = recruters;
    },

    changeObservers(observers) {
      this.observers = observers;
    },

    setDate(date) {
      this.date = date;
    },

    setDefault(interviews, stage) {
      const FIRST_ELEMENT = 0;
      const FIRST_INTERVIEW = this.interviews[FIRST_ELEMENT];

      const findedStageIndex = FIRST_INTERVIEW.stages.findIndex(
        (findStage) => findStage.name === stage
      );
      this.recruters = FIRST_INTERVIEW.stages[findedStageIndex].recruters.map(
        (mapRecruter) => mapRecruter.user
      );

      this.observers = FIRST_INTERVIEW.stages[findedStageIndex].observers.map(
        (mapObserver) => mapObserver.user
      );

      this.subject = FIRST_INTERVIEW.subject;
      this.telephone = FIRST_INTERVIEW.telephone;
      this.address = FIRST_INTERVIEW.address;
      this.message = FIRST_INTERVIEW.message;
    },
  },
};
</script>
