<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card class="pa-10">
      <Transition name="fade" mode="out-in">
        <v-row v-if="step === 1" class="d-flex flex-column">
          <div class="text-center">
            {{ $t("Select_wish_complete_interview") }}
          </div>
          <div class="my-10">
            <candidate-combobox
              :candidacies="candidacies"
              @change="setCandidates"
            />
          </div>

          <div class="text-right">
            <v-btn text small color="primary" @click="dialog = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn small color="primary" @click="step = 2">
              {{ $t("continue") }}
            </v-btn>
          </div>
        </v-row>
        <v-row v-else-if="step === 2" class="d-flex flex-column">
          <div class="text-center">
            {{ $t("How_candidate_wait_feedback") }}
          </div>
          <div class="my-10 px-12">
            <v-text-field
              v-model.number="waitingDays"
              v-mask="'##'"
              dense
              outlined
              prepend-inner-icon="event"
              :label="$t('days')"
              hide-details
            >
            </v-text-field>
          </div>
          <div class="text-right">
            <v-btn text small color="primary" @click="step = 1">
              {{ $t("back") }}
            </v-btn>
            <v-btn small color="primary" @click="step = 3">
              {{ $t("continue") }}
            </v-btn>
          </div>
        </v-row>
        <v-row v-else class="d-flex flex-column">
          <div class="text-center">
            {{ $t("customize_feedback") }}
          </div>
          <div class="mt-5">
            <tiptap-vuetify
              v-model="feedbackMessage"
              :extensions="extensions"
              placeholder
              class="mb-2"
              style="max-width: 100%"
            />
          </div>
          <div class="text-right">
            <v-btn text small color="primary" @click="step = 2">
              {{ $t("back") }}
            </v-btn>
            <v-btn small color="primary" @click="finish()">
              {{ $t("send") }}
            </v-btn>
          </div>
        </v-row>
      </Transition>
    </v-card>

    <progress-dialog :processing="isLoading" />
  </v-dialog>
</template>

<script>
const { format } = require("date-fns");
const { pt: ptPT, enUS } = require("date-fns/locale");
import { UPDATE_STATUS_INTERVIEW_MUTATION } from "../graphql/Mutation.gql";
import CandidateCombobox from "./form/CandidateCombobox.vue";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  name: "FinishInterview",

  components: {
    CandidateCombobox,
    ProgressDialog,
    TiptapVuetify,
  },

  data: () => ({
    dialog: false,
    waitingDays: 0,
    isSelectCandidates: true,
    step: 1,
    interviewId: null,
    status: "finished",
    candidacies: [],
    candidates: [],
    isLoading: false,
    feedbackMessage: "",
    currentStage: null,
    vacancy: "",
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),

  watch: {
    step(value) {
      console.log("step: ", value);
      // if (value === 3) {
      const { date, time } = this.currentStage;
      this.feedbackMessage = this.$t("send_finished_email", {
        candidate: this.$t("candidate"),
        vacancy: this.vacancy,
        dateTime: this.localDate(`${date} ${time}`),
        waitingDays: this.waitingDays,
      });
      // }
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("FinishInterview", ({ interviewId, interviews }) => {
      this.interviewId = interviewId;
      this.currentStage = interviews[0].stages.find(
        (findStage) => findStage.name === interviews[0].currentStage
      );
      this.candidacies = interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee
          : { fullName: mapInterview.email };
      });
      this.vacancy = interviews[0].vacancy;
      this.isSelectCandidates = true;

      this.dialog = true;
    });
  },

  computed: {
    days() {
      const items = [];
      for (let index = 1; index <= 30; index++) {
        if (index === 1) {
          items.push({ key: `${index} ${this.$t("day")}`, value: index });
        } else {
          items.push({ key: `${index} ${this.$t("days")}`, value: index });
        }
      }

      return items;
    },
  },

  methods: {
    async finish() {
      this.isLoading = true;
      const candidates = this.getCandidateIdsFromInterviews();
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_STATUS_INTERVIEW_MUTATION,
          variables: {
            interviewUpdateStatusInput: {
              interviewId: this.interviewId,
              candidates,
              status: this.status,
              waitingDays: this.waitingDays,
              cancellationReason: this.feedbackMessage,
            },
          },
        });
        this.dialog = false;
        const successMsg = this.$t("interview_ended_successfully");
        // eslint-disable-next-line no-undef
        Fire.$emit("SuccessDialog", successMsg);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.candidates.map((mapCandidate) => {
        return mapCandidate.id ? mapCandidate.id : mapCandidate.fullName;
      });

      return candidates;
    },

    setCandidates(candidates) {
      this.candidates = candidates;
    },

    localDate(dateTime) {
      const currentLang = this.$i18n.locale;

      let formattedDate;
      switch (currentLang) {
        case "en":
          formattedDate = format(new Date(dateTime), "PPPPpp", {
            locale: enUS,
          });
          break;

        default:
          formattedDate = format(new Date(dateTime), "PPPPpp", {
            locale: ptPT,
          });
          break;
      }

      return formattedDate;
    },
  },
};
</script>