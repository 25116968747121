<template>
  <v-combobox
    v-model="recruters"
    :items="isObserver ? filteredObservers : filteredRecruters"
    :label="isObserver ? $t('observers') : $t('recruters')"
    chips
    item-text="fullName"
    item-value="id"
    multiple
    dense
    outlined
    hide-details
    required
    :rules="isObserver ? [] : [required]"
    @change="$emit('change', recruters)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        small
        @click="data.select"
        @click:close="removeRecruter(data.item)"
      >
        <v-avatar left size="24">
          <v-img
            :src="
              data.item.photo
                ? `${apiUrl}/images/user/${data.item.photo}`
                : `/avatar.png`
            "
          ></v-img>
        </v-avatar>
        {{ data.item.name.split(" ")[0] }}
        {{ data.item.name.split(" ")[data.item.name.split(" ").length - 1] }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar size="24">
          <img
            :src="
              data.item.photo
                ? `${apiUrl}/images/user/${data.item.photo}`
                : `/avatar.png`
            "
          />
        </v-list-item-avatar>
        <v-list-item-content v-if="data.item.name">
          <v-list-item-title
            v-html="
              `${data.item.name.split(' ')[0]} ${
                data.item.name.split(' ')[data.item.name.split(' ').length - 1]
              }`
            "
          ></v-list-item-title>
          <v-list-item-subtitle
            v-html="data.item.office"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-combobox>
</template>

<script>
import { API_URL } from "@/api";
import { GET_USERS_ENTITY_QUERY } from "../../graphql/Query.gql";
export default {
  name: "RecruterCombobox",

  props: {
    existingRecruters: { type: Array },
    existingObservers: { type: Array },
    isObserver: { type: Boolean },
    currentRecruters: { type: Array },
  },

  data: () => ({
    apiUrl: API_URL,
    recruters: [],
    users: [],
  }),

  watch: {
    currentRecruters(val) {
      if (val.length === 0) {
        this.recruters = [];
      }
    },
  },

  apollo: {
    users: {
      query: GET_USERS_ENTITY_QUERY,
      fetchPolicy: "no-cache",
    },
  },

  created() {
    if (this.isObserver) {
      this.recruters = this.existingObservers;
    } else {
      this.recruters = this.existingRecruters;
    }
  },

  onActivated() {
    console.log("...onActivated");
    this.recruters = [];
  },

  computed: {
    filteredRecruters() {
      let resultItems = this.users;
      if (this.existingObservers && this.existingObservers.length > 0) {
        resultItems = resultItems.filter((recruter) => {
          const index = this.existingObservers.findIndex(
            (FindObserver) => FindObserver.id == recruter.id
          );
          return index === -1;
        });
      }

      return resultItems;
    },
    filteredObservers() {
      let resultItems = this.users;
      if (this.existingRecruters && this.existingRecruters.length > 0) {
        resultItems = resultItems.filter((findObserver) => {
          const index = this.existingRecruters.findIndex(
            (findRecruter) => findRecruter.id == findObserver.id
          );
          return index === -1;
        });
      }

      return resultItems;
    },
  },

  methods: {
    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Required.";
      }
      return !!value || "Required.";
    },

    removeRecruter(item) {
      const index = this.recruters.findIndex(
        (findRecruter) => findRecruter.id === item.id
      );

      if (index >= 0) {
        this.recruters.splice(index, 1);
      }
    },
  },
};
</script>