<template>
  <v-dialog v-model="dialogConfirm.dialog" persistent max-width="350">
    <v-card>
      <v-card-title class="justify-end primary--text caption pa-1">
        <v-btn x-small icon @click="$emit('no', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="primary--text text-center justify-center pt-1">
        <span class="pt-1 black--text" v-html="dialogConfirm.question">{{ dialogConfirm.question }}</span>
        <br /><br />
        <v-row justify="center">
          <v-col>
            <v-btn block color="primary" outlined @click="yes()">{{
              $t("yes")
            }}</v-btn>
          </v-col>
          <v-col>
            <v-btn block color="primary" @click="no()">{{
              $t("no")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialogInterview",

  props: {
    dialogConfirm: { type: Object },
  },

  data: () => ({
  }),

  created() {
  },

  methods: {
    yes() {
      this.$emit('yes', this.dialogConfirm.id);
    },
    no() {
      this.$emit('no');
    }
  }
};
</script>
