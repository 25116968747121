<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card class="py-10">
      <Transition name="fade" mode="out-in">
        <v-row v-if="isSelectCandidates" class="d-flex flex-column">
          <div class="text-center px-10">
            {{ $t("Select_wish_cancel_interview") }}
          </div>
          <div class="my-10 px-10">
            <candidate-combobox
              :candidacies="candidacies"
              @change="setCandidates"
            />
          </div>
          <div class="text-right px-10">
            <v-btn text small color="primary" @click="dialog = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn small color="primary" @click="isSelectCandidates = false">
              {{ $t("continue") }}
            </v-btn>
          </div>
        </v-row>

        <v-row v-else class="d-flex flex-column">
          <div class="text-center px-10">
            {{ $t("Send_message_cancelled") }}
          </div>
          <div class="mt-10 mb-5 px-12">
            <tiptap-vuetify
              v-model="cancellationReason"
              :extensions="extensions"
              placeholder
              class="mb-2"
              style="max-width: 100%"
            />
          </div>
          <div class="text-right px-10">
            <v-btn
              text
              small
              color="primary"
              @click="isSelectCandidates = true"
            >
              {{ $t("back") }}
            </v-btn>
            <v-btn small color="primary" @click="cancel()">
              {{ $t("send") }}
            </v-btn>
          </div>
        </v-row>
      </Transition>
    </v-card>

    <progress-dialog :processing="isLoading" />
  </v-dialog>
</template>

<script>
const { format } = require("date-fns");
const { pt: ptPT, enUS } = require("date-fns/locale");
import { UPDATE_STATUS_INTERVIEW_MUTATION } from "../graphql/Mutation.gql";
import CandidateCombobox from "./form/CandidateCombobox.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  name: "CancelInterview",

  components: {
    TiptapVuetify,
    CandidateCombobox,
  },

  data: () => ({
    dialog: false,
    isSelectCandidates: true,
    interviewId: null,
    status: "canceled",
    candidacies: [],
    candidates: [],
    cancellationReason: "",
    isLoading: false,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),

  // watch: {
  //   candidates() {
  //     const FIRST_ELEMENT = 0;
  //     const NUMBER_ONE = 1;
  //     const candidateName =
  //       this.candidates.length === NUMBER_ONE &&
  //       this.candidates[FIRST_ELEMENT].id
  //         ? this.candidates[FIRST_ELEMENT].fullName
  //         : "";
  //     this.cancellationReason = this.$t("send_rejected_email", {
  //       candidate: candidateName,
  //     });
  //   },
  // },

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("CancelInterview", ({ interviewId, interviews }) => {
      const FIRST_ELEMENT = 0;
      this.interviewId = interviewId;
      this.candidacies = interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee
          : { fullName: mapInterview.email };
      });

      const currentStage = interviews[FIRST_ELEMENT].stages.find(
        (findStage) => findStage.name === interviews[FIRST_ELEMENT].currentStage
      );

      const { date, time } = currentStage;
      this.cancellationReason = this.$t("send_cancelled_email", {
        candidate: "Candidate",
        vacancy: interviews[FIRST_ELEMENT].vacancy,
        dateTime: this.localDate(`${date} ${time}`),
      });

      this.isSelectCandidates = true;
      this.dialog = true;
    });
  },

  methods: {
    async cancel() {
      this.isLoading = true;
      const candidates = this.getCandidateIdsFromInterviews();
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_STATUS_INTERVIEW_MUTATION,
          variables: {
            interviewUpdateStatusInput: {
              interviewId: this.interviewId,
              candidates,
              status: this.status,
              cancellationReason: this.cancellationReason,
            },
          },
        });
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.candidates.map((mapCandidate) => {
        return mapCandidate.id ? mapCandidate.id : mapCandidate.fullName;
      });

      return candidates;
    },

    setCandidates(candidates) {
      this.candidates = candidates;
    },

    localDate(dateTime) {
      const currentLang = this.$i18n.locale;

      let formattedDate;
      switch (currentLang) {
        case "en":
          formattedDate = format(new Date(dateTime), "PPPPpp", {
            locale: enUS,
          });
          break;

        default:
          formattedDate = format(new Date(dateTime), "PPPPpp", {
            locale: ptPT,
          });
          break;
      }

      return formattedDate;
    },
  },
};
</script>