import gql from "graphql-tag";

export default gql `
  {
    id
    candidates {
      interviewee {
        id
        fullName
        email
        photo
        profession
        professionalExperience {
          entity
          description
          office
          startDate
          endDate
        }
      }
      stages {
        name
        date
        duration
        master
        observers {
          user {
            id
            name
            email
            photo
          }
          classification {
            id
            designation
            purpose
            index
            points
            evaluationPoints {
              name
              percentageWeight
              scoring
            }
            percentageWeight
            createdBy
            notes {
              id
              author
              authorName
              text
              date
            }
          }
          comments {
            id
            text
            date
          }
          status
          joined
        }
        recruters {
          user {
            id
            name
            email
            photo
          }
          classification {
            id
            designation
            purpose
            index
            points
            evaluationPoints {
              name
              percentageWeight
              scoring
            }
            percentageWeight
            createdBy
            notes {
              id
              author
              authorName
              text
              date
            }
          }
          comments {
            id
            text
            date
          }
          status
          joined
        }
        state
        time
        groupBy
      }
      currentStage
      salary
      maxSalary
      startedTime
      cancellationReason
      email
      notes {
        id
        author
        authorName
        text
        date
      }
      status
    }
    vacancy {
      id
      title
      client {
        id
        name
      }
    }
    entity {
      name
    }
    address
    telephone
    subject
    message
  }
`;